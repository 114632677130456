import store from "../../store";

import {actions} from './redux.report';
import GraphqlClient from "../../client/graphql.client";

import {ReportInputForm} from "src/types/generated";
import {ReportUpdateForm} from "src/types/generated";

export const readAll = async () => {
    try {
        const res = await GraphqlClient.reports({});
        let data = res.reports || [];
        store.dispatch(actions.readedAll(data));
        return {data: data}
    } catch (error) {
        store.dispatch(actions.readedAll([]));
        return {error: true, message: JSON.parse(JSON.stringify(error)).response.error}
    }
};

export const createReport = async (form?: ReportInputForm) => {
    try {
        const res = await GraphqlClient.createReport({form});
        store.dispatch(actions.created(res.createReport));
        return {data: res.createReport}
    } catch (error) {
        return {error: true, message: error.toString()}
    }
};


export const updateReport = async (id: string,form?: ReportUpdateForm) => {
    try {
        const res = await GraphqlClient.updateReport({id,form});
        store.dispatch(actions.created(res.updateReport));
        return {data: res.updateReport}
    } catch (error) {
        return {error: true, message: error.toString()}
    }
};


export const deleteReport = async (id: string) => {
    try {
        const res = await GraphqlClient.deleteReport({id});
        return {data: res.deleteReport}
    } catch (error) {
        return {error: true, message: error.toString()}
    }
};
