import { createSlice } from '@reduxjs/toolkit'

// export interface dossiers extends Array<dossier>{}


const dashboardGenSlice = createSlice({
  name: 'dashboardCommissionale',
  initialState: {
    loaded: false,
    data: []
  },
  reducers: {
    load: (state, action) => {
      state.loaded = true
      state.data = action.payload
    }
  }
})

export const actions = dashboardGenSlice.actions;
export default dashboardGenSlice.reducer;