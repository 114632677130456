import store from "../../store";

import {actions} from './redux.dashboardCommissionale';
import GraphqlClient from "../../client/graphql.client";
import { Credentials, Exact, Filters, FiltersRic } from "src/types/generated";

export const loadDashboardCommissionale = async () => {
    try {
      const res = await GraphqlClient.ottieniDashboardCommissionale();
      store.dispatch(actions.load(res.ottieniDashboardCommissionale))
    } catch (error) {
      return { loaded: false, message: error.toString() }
    }
  };
