import {useEffect, useState} from 'react';
import type {FC} from 'react';
import {Link as RouterLink, useNavigate, useLocation, useParams} from 'react-router-dom';
import * as Yup from 'yup';
import {Formik,ErrorMessage} from 'formik';
import {useSnackbar} from 'notistack';
import {
    Box, Breadcrumbs,
    Switch,
    Card,
    CardContent,
    CardHeader,
    Checkbox, Container,
    FormControlLabel,
    FormHelperText,
    Autocomplete,
    Grid, Link,
    /* TextField, */
    Typography
} from '@material-ui/core';
import {useTranslation} from "react-i18next";

import useSettings from "../../hooks/useSettings";
import {Helmet} from "react-helmet-async";
import ChevronRightIcon from "../../icons/ChevronRight";
import ArrowLeftIcon from "../../icons/ArrowLeft";
import {updateReport} from "./commands.report";

/*****************************************/
import Button_MUI from '@material-ui/core/Button';
import {Button} from '../../components/Button/Button'
import {TextField} from '../../components/TextField/TextField'
/*****************************************/
import GraphqlClient from "src/client/graphql.client";
import {Report} from "src/types/generated";

const UpdateReport: FC = (props) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const {settings} = useSettings();
    const {enqueueSnackbar} = useSnackbar();
    const [report, setReport] = useState({} as Report);

    const [loadingData, setLoadingData] = useState(false);

    const idReceived = params.id;

    useEffect(() => {

        const loadData = async () => {
            try {
                setLoadingData(true)
                const res = await GraphqlClient.report({id: idReceived});
                setReport(res.report);
            } catch (e) {
                enqueueSnackbar("Errore", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });
            } finally {
                setLoadingData(false);
            }
        }

        loadData();
    }, []);


    return (
        <>
            <Helmet>
                <title>t("reportupdate_title") </title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 8
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                {t("report_update")}
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small"/>}
                                sx={{mt: 1}}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/dashboard"
                                    variant="subtitle2"
                                >
                                    Dashboard
                                </Link>
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/reports"
                                    variant="subtitle2"
                                >
                                        Report
                                </Link>
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    {t("report_update_breadcrumb")}
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item>
                            <Box sx={{m: -1}}>
                                <Button
                                    color="primary"
                                    component={RouterLink}
                                    startIcon={<ArrowLeftIcon fontSize="small"/>}
                                    sx={{mt: 1}}
                                    to="/reports"
                                    variant="outlined"
                                    label={t("back")}
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    <Box sx={{mt: 3}}>
                        <Formik
                            initialValues={{
                                /*TODO: Collegare le props con l'oggetto già popolato: report */
                                 form_description: '',
                            }}

                            onSubmit={async (values, {
                                setErrors,
                                setStatus,
                                setSubmitting
                            }): Promise<void> => {
                                setSubmitting(true);
                                let {form_description} = values;
                                const res = await updateReport(
                                        idReceived,{description:form_description}
                                );
                                if (res.error && res.error == true) {
                                    console.error(res.message);
                                    setStatus({success: false});
                                    setSubmitting(false);
                                    enqueueSnackbar(res.message, {
                                        anchorOrigin: {
                                            horizontal: 'right',
                                            vertical: 'top'
                                        },
                                        variant: 'error'
                                    });
                                    return;
                                }

                                setStatus({success: true});
                                setSubmitting(false);
                                navigate('/reports');
                            }}
                        >
                            {({
                                  errors,
                                  handleBlur,
                                  handleChange,
                                  handleSubmit,
                                  isSubmitting,
                                  setFieldValue,
                                  touched,
                                  values
                              }): JSX.Element => (
                                <form
                                    onSubmit={handleSubmit}
                                    {...props}
                                >
                                    <Box sx={{p: 3}}>

                                        <Grid
                                            container
                                            spacing={3}
                                        >
                                            <Grid
                                                item
                                                md={12}
                                                xs={12}
                                            >
                                                <Card>
                                                    <CardContent>
                                                        <CardHeader title="Form"/>
                                                          
                                                                <Box sx={{mt: 2}}>
                                                            <TextField
                                                                required
                                                                error={Boolean(touched.form_description && errors.form_description)}
                                                                fullWidth
                                                                helperText={touched.form_description && errors.form_description}
                                                                label="form_description"
                                                                input_id="form_description"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.form_description}
                                                                variant="outlined"
                                                            />
                                                        </Box>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                mt: 3
                                            }}
                                        >
                                            <Button
                                                color="primary"
                                                disabled={isSubmitting}
                                                type="submit"
                                                variant="contained"
                                                label={t("report_update_save_btn")}
                                            />
                                        </Box>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default UpdateReport;
