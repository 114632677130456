import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useNavigate, useLocation, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
    Autocomplete,
    Box, Breadcrumbs,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox, Container,
    Divider,
    FormControlLabel,
    FormHelperText,
    Grid, Link,
    TextField,
    Typography
} from '@material-ui/core';
import useSettings from "../../hooks/useSettings";
import { Helmet } from "react-helmet-async";
import ChevronRightIcon from "../../icons/ChevronRight";
import ArrowLeftIcon from "../../icons/ArrowLeft";
import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import { FilePondErrorDescription, FilePondFile } from "filepond";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';


import { useSelector as useReduxSelector } from '../../store'

import JSZip, { folder } from 'jszip'

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import GraphqlClient from "../../client/graphql.client";
import { AccettaPreventivo, Allegati, FileConIntero, InputRichiestaPreventivi } from 'src/types/generated';


// filepond plugin
registerPlugin(FilePondPluginFileValidateType);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        resetContainer: {
            padding: theme.spacing(3),
        },
    }),
);



const ApprovaPreventivo: FC = (props) => {

    const state = useReduxSelector(state => state.richieste)
    const configuration = useReduxSelector(state => state.configuration)
    const { settings } = useSettings();
    const navigate = useNavigate();


    const params = useParams();

    const richiestaId = String(params.richiestaId)
    const preventivoId = String(params.preventivoId)
    const stato = String(params.stato)

    const { enqueueSnackbar } = useSnackbar();

    const arrayTipoDocumento = (configuration?.itemsForSelects && configuration?.itemsForSelects["tipoDocumento"]) || [];

    const [fileDocumentoPrecontrattuale, setFileDocumentoPrecontrattuale] = useState([])
    const [filePrecontrattualeBanca, setFilePrecontrattualeBanca] = useState([])
    const [nota, setNota] = useState('')

    /*  const [base64zip, setB64zip] = useState("");
     const [FilesInfo, setFilesInfo] = useState() */
     let base64zip = "";
     let FilesInfo: FileConIntero[] = [];

     const creaZip = async (files) => {
         
         var zip = new JSZip();
         for (let i = 0; i < files.length; i++) {
             zip.file(files[i].file.name, files[i].file)
         }
 
         // creo la struttura files da mandare in input
         let listInfoFiles: FileConIntero[] = [];
        fileDocumentoPrecontrattuale.map(
            elem => {
                let fileInfo: FileConIntero = {
                    tipoDocumento: 145,
                    nomeFile: elem.file.name,
                    fileType: "pdf"
                }
                listInfoFiles.push(fileInfo)
            }
        )
        filePrecontrattualeBanca.map(
            elem => {
                let fileInfo: FileConIntero = {
                    tipoDocumento: 144,
                    nomeFile: elem.file.name,
                    fileType: "pdf"
                }
                listInfoFiles.push(fileInfo)
            }
        )
        FilesInfo = listInfoFiles;
 
        const content = await zip.generateAsync({ type: "base64" });
        //setB64zip(content);
        base64zip = content;
        
        //console.log("BASE64 GENERATO-> ", base64zip)

       /*   // codice per debug in caso non funzioni il base64, crea un download per verifica locale
         var link = document.createElement('a');
         link.href = "data:application/zip;base64," + content;
         link.download = "nuovaRichiesta.zip";
         document.body.appendChild(link);
         link.click();
         document.body.removeChild(link); */
 
     }

    const accettaPreventivo = async () => {
        // creo un array unico con tutti i files
        let files = fileDocumentoPrecontrattuale.concat(filePrecontrattualeBanca);
        if (files.length === 0 || nota.length === 0) {
            enqueueSnackbar('Richiesta incompleta', {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
            return
        }

        await creaZip(files);

        let allegati: Allegati = {
            base64zip: base64zip,
            files: FilesInfo
        }

        let input: AccettaPreventivo = {
            richiestaId: richiestaId,
            preventivoId: preventivoId,
            nota: nota,
            allegati: allegati,
        }


        try {
            //console.log(input)
            await GraphqlClient.accettaPreventivo({
                input: input
            })

            enqueueSnackbar('Documento caricato con successo', {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'success'
            });

           
            navigate('/richieste');

        } catch (e) {
            console.error(e);
            enqueueSnackbar('Impossibile caricare documento', {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }
        navigate(`/richieste/dettagli/${richiestaId}/${stato}`)
    }


    /* useEffect(() => {
        console.log(tipoDocumento)
        console.log(fileDocumento)
        console.log(FilesInfo)
    }) */

    return (
        <>
            <Helmet>
                <title>Accetta Preventivo</title>
            </Helmet>

            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 8
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                Accetta Pratica
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/dashboard"
                                    variant="subtitle2"
                                >
                                    Dashboard
                                </Link>
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/richieste"
                                    variant="subtitle2"
                                >
                                    Richieste
                                </Link>
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    Accetta Pratica
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 3 }}>

                        <Card>
                            <CardHeader title="Aggiungi Documento" />
                            <Divider />
                            <CardContent>
                                <Box sx={{ mt: 2 }}>
                                    <Typography>
                                        Precontrattuale distributore firmata:
                                    </Typography>
                                </Box>
                                <Box sx={{ m: 5 }}>
                                    <div>
                                        <FilePond
                                            files={fileDocumentoPrecontrattuale}
                                            allowMultiple={false}
                                            onupdatefiles={setFileDocumentoPrecontrattuale}
                                            allowFileTypeValidation
                                            fileValidateTypeLabelExpectedTypes="{allTypes}"
                                            name="documento"
                                            acceptedFileTypes={['application/pdf']}
                                            labelIdle='Trascina e lascia il pdf o <span class="filepond--label-action">Cerca</span>'
                                        />
                                    </div>
                                </Box>
                                <Box sx={{ mt: 2 }}>
                                    <Typography>
                                        Precontrattuale banca erogante firmata:
                                    </Typography>
                                </Box>
                                <Box sx={{ m: 5 }}>
                                    <div>
                                        <FilePond
                                            files={filePrecontrattualeBanca}
                                            allowMultiple={false}
                                            onupdatefiles={setFilePrecontrattualeBanca}
                                            allowFileTypeValidation
                                            fileValidateTypeLabelExpectedTypes="{allTypes}"
                                            name="documento"
                                            acceptedFileTypes={['application/pdf']}
                                            labelIdle='Trascina e lascia il pdf o <span class="filepond--label-action">Cerca</span>'

                                        />
                                    </div>
                                </Box>
                                <Box sx={{ mt: 2 }}>
                                    <Typography
                                        style={{
                                            marginBottom: '10px'
                                        }}
                                    >
                                        Nota da allegare:
                                    </Typography>
                                    <TextField                                        
                                        label="Nota"
                                        fullWidth
                                        value={nota}
                                        onChange={(e) => {
                                            setNota(e.target.value)
                                        }}
                                    />
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        mt: 3
                                    }}
                                >
                                    <Button
                                        color="primary"
                                        //disabled={isSubmitting}
                                        type="submit"
                                        variant="contained"
                                        onClick={() => accettaPreventivo()}
                                    >
                                        Aggiungi Documenti
                                    </Button>
                                </Box>
                            </CardContent>
                        </Card>


                    </Box>

                </Container>
            </Box>



        </>
    )
};

export default ApprovaPreventivo;
