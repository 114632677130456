import React, { FC, useEffect, useState } from 'react'
import {
    Box,
    Grid,
    Card,
    Typography,
    /* Table_MUI */
    TableHead,
    TableRow,
    TableCell,
    Divider,
    CardContent,
    TableBody,
    TableFooter,
    Skeleton,
    CardHeader,
    Chip,
    makeStyles, Step, StepLabel, IconButton, Button, TextField, DialogActions, DialogContentText, DialogContent, DialogTitle, Dialog, Autocomplete
} from '@material-ui/core'
import Table_MUI from '@material-ui/core/Table'

import GraphqlClient from 'src/client/graphql.client'
import { useSnackbar } from 'notistack'
//import dayjs from 'dayjs';
import { useParams } from 'react-router-dom'
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import {
    Table,
    SelectColumnFilter,
    DatePickerRangeFilter,
    DatePickerRangeFilterMethod
} from '../../../components/Table/Table'
import { SingolaPratica, PraticheList, Note, Entity } from 'src/types/generated'
import Scrollbar from 'src/material/Scrollbar'
import moment from "moment/moment";
import Stepper from "@material-ui/core/Stepper";
import Tooltip from "@material-ui/core/Tooltip";
import { useSelector as useReduxSelector } from "../../../store";
import _ from "lodash";

interface DettaglioPraticaProps {
    pratica: SingolaPratica;
}

const useStyles = makeStyles({
    rowDiv: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: '5px',
        paddingBottom: '5px',
    },
    typogr: {
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
        justifyContent: 'center'
    },
    secondatyInfo: {
        color: 'rgb(145, 158, 171)',
        fontSize: '14px'
    }
})

const DettaglioPratica: FC<DettaglioPraticaProps> = ({ pratica }: DettaglioPraticaProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const style = useStyles();
    const ogg = pratica || {};
    const oggPratica = pratica?.praticaCqs || {};
    const cliente = pratica?.cliente || {};
    const datoreLavoro = pratica?.rapportoLavoro?.datoreLavoro || {};

    const configuration = useReduxSelector(state => state.configuration.itemsMap)

    const getConfig = (fieldName, fieldValue) => {
        if (!configuration[fieldName]) return fieldValue;
        if (!configuration[fieldName][fieldValue]) return fieldValue;

        return configuration[fieldName][fieldValue];
    }

    const [nuovaNota, setNuovaNota] = useState("")
    const [tipoNota, setTipoNota] = useState(null)
    const [openNuovaNotaDialog, setOpenNuovaNotaDialog] = React.useState(false);

    const handleClickOpenNuovaNota = () => {
        setOpenNuovaNotaDialog(true);
        setNuovaNota("")
    };

    const handleCloseNuovaNotaDialog = () => {
        setOpenNuovaNotaDialog(false);
    };


    const checkIfEmpty = () => {
        let check = false;
        if (nuovaNota === "") {
            check = true
        }
        if (check)
            return false
        return true
    }

    const inserisciNota = async () => {
        if (!checkIfEmpty()) {
            enqueueSnackbar("Non è possibile inserire note vuote", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
            return;
        }
        try {
            let elencoNoteDaInserire: Note[] = [];
            let singolaNota: Note = {
                Nota: nuovaNota,
                TipoNota: 40
            }
            elencoNoteDaInserire.push(singolaNota)
            //console.log("queste sono le note da inserire-> ", entity)

            let Entity: Entity = {
                Tipo: 'pratica',
                Id: pratica.praticaCqs.id
            };

            await GraphqlClient.createNota({
                entity: Entity,
                notes: elencoNoteDaInserire
            });

            enqueueSnackbar("Nota inserita", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'success'
            });
            setOpenNuovaNotaDialog(false);
        } catch (e) {
            console.error(e);
            enqueueSnackbar("Impossibile inserire la nota", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }
        return
    }


    console.log('OGGGGGGGGGGGGGGGGGGGG', ogg);

    return (
        <>

            <Grid
                container
                spacing={3}
            >

                <Grid item xs={3}>
                    <Card>
                        <CardHeader
                            title="Anagrafica cliente"
                        />
                        <Divider />
                        <CardContent>
                            <Typography style={{ display: 'inline-block', width: '50%', }} gutterBottom
                                variant="subtitle1" component="h2">
                                Cliente
                            </Typography>
                            <Typography style={{ display: 'inline-block', width: '50%', }} variant="body2" component="p">
                                {cliente.cognome || "" + " " + cliente.nome || ""}
                            </Typography>

                            <Divider />

                            <Typography style={{ display: 'inline-block', width: '50%', }} gutterBottom
                                variant="subtitle1" component="h2">
                                Data Nascita
                            </Typography>
                            <Typography style={{ display: 'inline-block', width: '50%', }} variant="body2" component="p">
                                {moment(cliente.DataNascita).format("DD-MM-YYYY")}
                            </Typography>

                            <Divider />

                            <Typography style={{ display: 'inline-block', width: '50%', }} gutterBottom
                                variant="subtitle1" component="h2">
                                Luogo Nascita
                            </Typography>
                            <Typography style={{ display: 'inline-block', width: '50%', }} variant="body2" component="p">
                                {cliente.cittaNascita}
                            </Typography>

                            <Divider />

                            <Typography style={{ display: 'inline-block', width: '50%', }} gutterBottom
                                variant="subtitle1" component="h2">
                                Sesso
                            </Typography>
                            <Typography style={{ display: 'inline-block', width: '50%', }} variant="body2" component="p">
                                {cliente.sesso}

                            </Typography>

                            <Divider />

                            <Typography style={{ display: 'inline-block', width: '50%', }} gutterBottom
                                variant="subtitle1" component="h2">
                                Codice Fiscale
                            </Typography>
                            <Typography style={{ display: 'inline-block', width: '50%', }} variant="body2" component="p">
                                {cliente.codiceFiscale}

                            </Typography>

                            <Divider />

                            <Typography style={{ display: 'inline-block', width: '50%', }} gutterBottom
                                variant="subtitle1" component="h2">
                                Telefono
                            </Typography>
                            <Typography style={{ display: 'inline-block', width: '50%', }} variant="body2" component="p">
                                {ogg?.recapito?.telefono1}

                            </Typography>

                            <Divider />

                            <Typography style={{ display: 'inline-block', width: '50%', }} gutterBottom
                                variant="subtitle1" component="h2">
                                Cellulare
                            </Typography>
                            <Typography style={{ display: 'inline-block', width: '50%', }} variant="body2" component="p">
                                {ogg?.recapito?.cellulare}


                            </Typography>

                            <Divider />

                            <Typography style={{ display: 'inline-block', width: '50%', }} gutterBottom
                                variant="subtitle1" component="h2">
                                Email
                            </Typography>
                            <Typography style={{ display: 'inline-block', width: '50%', }} variant="body2" component="p">
                                {ogg?.recapito?.eMail}

                            </Typography>

                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={9}>
                    <Card>
                        <CardHeader
                            title="Dati Pratica - IBL Family"
                        />
                        <Divider />
                        <CardContent>
                            <Typography style={{ display: 'inline-block', width: '35%', }} gutterBottom
                                variant="subtitle1" component="h2">
                                Codice
                            </Typography>
                            <Typography style={{ display: 'inline-block', width: '65%', }} variant="body2" component="p">
                                {oggPratica.codice}
                            </Typography>

                            <Divider />

                            <Typography style={{ display: 'inline-block', width: '35%', }} gutterBottom
                                variant="subtitle1" component="h2">
                                Titolare / Venditore
                            </Typography>
                            <Typography style={{ display: 'inline-block', width: '65%', }} variant="body2" component="p">
                                {ogg?.titolareCognome} {ogg?.titolareNome} / {ogg?.venditoreCognome} {ogg?.venditoreNome}
                            </Typography>

                            <Divider />

                            <Typography style={{ display: 'inline-block', width: '35%', }} gutterBottom
                                variant="subtitle1" component="h2">
                                Collocatore
                            </Typography>
                            <Typography style={{ display: 'inline-block', width: '65%', }} variant="body2" component="p">
                                {ogg?.collocatoreCognome} {ogg?.collocatoreNome}
                            </Typography>

                            <Divider />

                            <Typography style={{ display: 'inline-block', width: '35%', }} gutterBottom
                                variant="subtitle1" component="h2">
                                Canale acquisizione
                            </Typography>
                            <Typography style={{ display: 'inline-block', width: '65%', }} variant="body2" component="p">
                                {ogg.canaleAcquisizioneDesc}
                            </Typography>

                            <Divider />

                            <Typography style={{ display: 'inline-block', width: '35%', }} gutterBottom
                                variant="subtitle1" component="h2">
                                Data acquisizione documenti
                            </Typography>
                            <Typography style={{ display: 'inline-block', width: '65%', }} variant="body2" component="p">
                                {moment(oggPratica?.dataRicezioneDocumentiFirmati).format("DD-MM-YYYY")}                            </Typography>

                            <Divider />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card>

                        <CardHeader title="Informazioni aggiuntive anagrafica cliente" />

                        <Divider />

                        <CardContent>
                            <div className={style.rowDiv}>
                                <Typography
                                    className={style.typogr}
                                    gutterBottom
                                    variant="subtitle1"
                                    component="h2"
                                >
                                    Residenza
                                </Typography>
                                <Typography style={{ display: 'inline-block', width: '50%', }} variant="body2"
                                    component="p">
                                    <p>{ogg?.recapito?.viaResidenza} {ogg?.recapito?.civicoResidenza}</p>
                                    <p className={style.secondatyInfo}>{ogg?.recapito?.capResidenza} {ogg?.recapito?.cittaResidenza} ({ogg?.recapito?.provinciaResidenza})</p>
                                </Typography>
                            </div>
                            <Divider />

                            <div className={style.rowDiv}>
                                <Typography className={style.typogr} gutterBottom variant="subtitle1" component="h2">
                                    Domicilio
                                </Typography>
                                <Typography style={{ display: 'inline-block', width: '50%', }} variant="body2"
                                    component="p">
                                    <p>{ogg?.recapito?.viaDomicilio} {ogg?.recapito?.civicoDomicilio}</p>
                                    <p className={style.secondatyInfo}>{ogg?.recapito?.capDomicilio} {ogg?.recapito?.cittaDomicilio} ({ogg?.recapito?.provinciaDomicilio})</p>
                                </Typography>
                            </div>

                            <Divider />

                            <div className={style.rowDiv}>
                                <Typography className={style.typogr} gutterBottom variant="subtitle1" component="h2">
                                    Documento d'identità
                                </Typography>
                                <Typography style={{ display: 'inline-block', width: '50%', }} variant="body2"
                                    component="p">
                                    <p>Carta d'identità</p>
                                    <p className={style.secondatyInfo}>Numero: {ogg?.identificazione?.numeroDocumento}</p>
                                    <p className={style.secondatyInfo}>Scadenza: {moment(ogg?.identificazione?.dataScadenza).format("DD-MM-YYYY")}</p>
                                    <p className={style.secondatyInfo}>Rilasciata
                                        da: {ogg?.identificazione?.enteRilascio}</p>
                                </Typography>
                            </div>

                            <Divider />

                            <div className={style.rowDiv}>
                                <Typography className={style.typogr} gutterBottom variant="subtitle1" component="h2">
                                    Coordinate bancarie
                                </Typography>
                                <Typography style={{ display: 'inline-block', width: '50%', }} variant="body2"
                                    component="p">
                                    <p>Metodo di pagamento: {ogg?.erogazione?.assegno ? "Assegno" : "Bonifico bancario"}</p>
                                    <p className={style.secondatyInfo}>IBAN: {ogg?.erogazione?.iban ? ogg?.erogazione?.iban : "-"} </p>
                                </Typography>
                            </div>

                            <Divider />

                            <div className={style.rowDiv}>
                                <Typography className={style.typogr} gutterBottom variant="subtitle1" component="h2">
                                    Medico curante
                                </Typography>
                                <Typography style={{ display: 'inline-block', width: '50%', }} variant="body2"
                                    component="p">
                                    <p>{ogg?.medicoCurante?.cognome} {ogg?.medicoCurante?.nome}</p>
                                    <p className={style.secondatyInfo}>{ogg?.medicoCurante?.indirizzo} {ogg?.medicoCurante?.indirizzo}, {ogg?.medicoCurante?.citta} ({ogg?.medicoCurante?.provincia})</p>
                                    <p className={style.secondatyInfo}>Tel.: {ogg?.medicoCurante?.telefono}</p>
                                    <p className={style.secondatyInfo}>E-Mail: {ogg?.medicoCurante?.eMail}</p>
                                </Typography>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card>
                        <CardHeader
                            title="Impiego"
                        />

                        <Divider />

                        <CardContent>

                            <div className={style.rowDiv}>
                                <Typography className={style.typogr} gutterBottom variant="subtitle1" component="h2">
                                    Datore di lavoro
                                </Typography>
                                <Typography style={{ display: 'inline-block', width: '50%', }} variant="body2"
                                    component="p">
                                    <p>{datoreLavoro.ragioneSociale}</p>
                                    <p className={style.secondatyInfo}>P.IVA: {datoreLavoro?.partitaIva}</p>
                                    <p className={style.secondatyInfo}>{datoreLavoro?.via + " " + datoreLavoro?.civico}</p>
                                    <p className={style.secondatyInfo}>{datoreLavoro?.cap} {datoreLavoro?.citta} ({datoreLavoro?.provincia})</p>
                                    <p className={style.secondatyInfo}>Tel.: {datoreLavoro?.telefono1}</p>
                                    <p className={style.secondatyInfo}>E-Mail: {datoreLavoro?.eMail}</p>
                                    <p className={style.secondatyInfo}>PEC: {datoreLavoro?.pec}</p>
                                    <p className={style.secondatyInfo}>Referente: {datoreLavoro?.personaRiferimento || "Nessuno"}</p>
                                </Typography>
                            </div>

                            <Divider />

                            <div className={style.rowDiv}>
                                <Typography className={style.typogr} gutterBottom variant="subtitle1" component="h2">
                                    Dati cedente
                                </Typography>
                                <Typography style={{ display: 'inline-block', width: '50%', }} variant="body2"
                                    component="p">
                                    <p>Qualifica: {getConfig("statoLavorativo", ogg.rapportoLavoro?.statoLavorativo)}</p>
                                    <p className={style.secondatyInfo}>Data
                                        assunzione: {moment(ogg?.rapportoLavoro?.data_assunzione).format("DD-MM-YYYY")}</p>
                                    <p className={style.secondatyInfo}>Stipendio
                                        lordo: {ogg?.rapportoLavoro?.stipendioLordo?.toLocaleString()}</p>
                                    <p className={style.secondatyInfo}>Stipendio
                                        netto: {ogg?.rapportoLavoro?.stipendioNetto?.toLocaleString()}</p>
                                </Typography>
                            </div>

                            <Divider />

                            <div className={style.rowDiv}>
                                <Typography className={style.typogr} gutterBottom variant="subtitle1" component="h2">
                                    Stato TFR
                                </Typography>
                                <Typography style={{ display: 'inline-block', width: '50%', }} variant="body2"
                                    component="p">
                                    {getConfig("statoTfr", ogg?.rapportoLavoro?.statoTfr)}
                                </Typography>
                            </div>

                            <Divider />

                            <div className={style.rowDiv}>
                                <Typography className={style.typogr} gutterBottom variant="subtitle1" component="h2">
                                    Gestione INPS
                                </Typography>
                                <Typography style={{ display: 'inline-block', width: '50%', }} variant="body2"
                                    component="p">
                                    {getConfig("statoInps", ogg?.praticaCqs?.statoPraticaINPS)}
                                </Typography>
                            </div>

                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            title="Impegni"
                        />
                        {ogg?.listImpegni?.map(impegno => (
                            <>
                                <Divider />
                                <CardContent>
                                    <div className={style.rowDiv}>
                                        <Typography className={style.typogr} gutterBottom variant="subtitle1"
                                            component="h2">
                                            {getConfig("tipoImpegno", impegno.tipoImpegno)}
                                        </Typography>
                                        <Typography style={{ display: 'inline-block', width: '50%', }} variant="body2"
                                            component="p">
                                            <p>IBL</p>
                                            <p className={style.secondatyInfo}>Rata: {impegno.rata?.toLocaleString()} €</p>
                                            <p className={style.secondatyInfo}>Durata: {impegno.durata}</p>
                                            <p className={style.secondatyInfo}>Rate residue: {impegno.rateResidue}</p>
                                            <p className={style.secondatyInfo}>Importo
                                                estinzione: {impegno.importoEstinzione?.toLocaleString()} €</p>
                                            <p className={style.secondatyInfo}>Estinzione: {impegno.estinzione}</p>
                                            <p className={style.secondatyInfo}>Data di
                                                decorrenza: {moment(impegno.dataDecorrenza).format("DD-MM-YYYY")}</p>
                                            <p className={style.secondatyInfo}>Stato
                                                estinzione: {getConfig("statoEstinzione", impegno.statoEstinzione)}</p>
                                        </Typography>
                                    </div>
                                </CardContent>
                            </>
                        ))}

                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            title="Pratica"
                        />
                        <Divider />
                        <CardContent>
                            <div className={style.rowDiv}>
                                <Typography className={style.typogr} gutterBottom variant="subtitle1" component="h2">
                                    Polizza assicurativa
                                </Typography>
                                <Typography style={{ display: 'inline-block', width: '50%', }} variant="body2"
                                    component="p">
                                    <p>{ogg?.praticaCqs?.compagniaAssicurativaPolizza}</p>
                                    <p className={style.secondatyInfo}>Data
                                        richiesta: {moment(ogg?.praticaCqs?.dataRichiestaPolizza).format("DD-MM-YYYY")}</p>
                                    <p className={style.secondatyInfo}>Data
                                        emissione: {moment(ogg?.praticaCqs?.dataEmissionePolizza).format("DD-MM-YYYY")}</p>
                                    <p className={style.secondatyInfo}>Download PDF - TODO</p>
                                </Typography>
                            </div>

                            <Divider />

                            <div className={style.rowDiv}>
                                <Typography className={style.typogr} gutterBottom variant="subtitle1" component="h2">
                                    Acconti
                                </Typography>
                                <Typography style={{ display: 'inline-block', width: '50%', }} variant="body2"
                                    component="p">
                                    <div style={{ marginBottom: '5px' }}>
                                        <p>Primo acconto</p>
                                        <p className={style.secondatyInfo}>Data ultima modifica: {moment(ogg?.praticaCqs?.dataUltimaModificaAcconto).format("DD-MM-YYYY")}</p>
                                        <p className={style.secondatyInfo}>Stato acconto: {getConfig("statoAcconto", ogg?.praticaCqs?.statoAcconto)}</p>
                                        <p className={style.secondatyInfo}>Importo: {ogg?.praticaCqs?.importoAcconto?.toLocaleString()} €</p>
                                        <p className={style.secondatyInfo}>Download PDF - TODO</p>
                                    </div>
                                    <div>
                                        <p>Secondo acconto</p>
                                        <p className={style.secondatyInfo}>Data ultima modifica: {moment(ogg?.praticaCqs?.dataUltimaModificaSecondoAcconto).format("DD-MM-YYYY")}</p>
                                        <p className={style.secondatyInfo}>Stato acconto: {getConfig("statoAcconto", ogg?.praticaCqs?.statoSecondoAcconto)}</p>
                                        <p className={style.secondatyInfo}>Importo: {ogg?.praticaCqs?.importoSecondoAcconto?.toLocaleString()} €</p>
                                        <p className={style.secondatyInfo}>Download PDF - TODO</p>
                                    </div>
                                </Typography>

                            </div>


                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <div>
                        <Dialog
                            open={openNuovaNotaDialog}
                            onClose={handleCloseNuovaNotaDialog}
                            aria-labelledby="form-dialog-title"
                            fullWidth={true}
                            maxWidth={'md'}
                        >
                            <DialogTitle id="titleNota2">Aggiungi nota</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Inserisci la tipologia della nota e il suo contenuto
                                </DialogContentText>
                                <Autocomplete
                                    id="tipoNota"
                                    options={[
                                        {
                                            description: "GENERICO",
                                            codice: 40
                                        },
                                        {
                                            description: "ACCONTO",
                                            codice: 41
                                        },
                                        {
                                            description: "ESTINZIONE",
                                            codice: 42
                                        }
                                    ]}
                                    getOptionLabel={(option: any) => option.description}
                                    onChange={(e, value: any) => {
                                        setTipoNota(value?.codice)
                                    }}
                                    renderInput={(params) => <TextField {...params}
                                        label="Tipo Nota"
                                        name="tipoNota"
                                        value={tipoNota}
                                        variant="outlined"
                                    />
                                    }
                                />
                                <TextField
                                    autoFocus
                                    margin="normal"
                                    id="nota"
                                    label="Contenuto"
                                    type="text"
                                    fullWidth
                                    value={nuovaNota}
                                    onChange={
                                        (e) => {
                                            setNuovaNota(e.target.value)
                                        }
                                    }

                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseNuovaNotaDialog} color="primary">
                                    Annulla
                                </Button>
                                <Button onClick={inserisciNota} color="primary">
                                    Invia
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    <Card>
                        <CardHeader
                            title="Lista Note"
                            action={
                                <IconButton onClick={handleClickOpenNuovaNota}>
                                    <AddCircleIcon color="primary" fontSize="large" />
                                </IconButton>
                            }
                        />
                        <Scrollbar>
                            <Table
                                Data={pratica.listNote || []}
                                Columns={
                                    [
                                        {
                                            Header: 'Tipo',
                                            accessor: 'TipoNota',
                                            width: 100,
                                            Cell: (row) => {
                                                return <Chip
                                                    label={row.row.original.TipoNota === 40 ? "GENERICO" : row.row.original.TipoNota === 41 ? "ACCONTO" : "ESTINZIONE"}
                                                    style={{ backgroundColor: '#2196f3\n' }} />
                                            }
                                        },
                                        {
                                            Header: 'Data inserimento',
                                            accessor: 'DataInserimento',
                                            Cell: (row) => {
                                                return moment(new Date(row.row.original.DataInserimento)).format("DD MMM YYYY | HH:mm")
                                            },
                                            width: 120
                                        },
                                        {
                                            Header: 'Mittente',
                                            accessor: 'UtenteCognome',
                                            Cell: (row) => {
                                                let str = row.row.original.UtenteCognome + " " + row.row.original.UtenteNome;
                                                let splitStr = str.toLowerCase().split(' ');
                                                for (let i = 0; i < splitStr.length; i++) {
                                                    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
                                                }
                                                return splitStr.join(' ')
                                            },
                                            width: 150
                                        },
                                        {
                                            Header: 'Testo',
                                            accessor: 'Nota',
                                            width: 200,
                                            Cell: (row) => {
                                                if (row.value.length >= 80) {
                                                    return row.value.substring(0, 80) + '...'
                                                } else {
                                                    return row.value
                                                }
                                            }
                                        }
                                    ]
                                }
                            />
                        </Scrollbar>
                    </Card>
                </Grid>
            </Grid>

        </>
    )
}

export default DettaglioPratica
