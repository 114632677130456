import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

const resources = {
    it: {
        translation: {
            'loginHeader': 'Benvenuto',
            'loginDescription': 'Accedi alla piattaforma',
            'loginBtn': 'Accedi',
            'logout': 'Disconetti',
            'demoLabel': 'Utilizzare',
            'forgotPwd': 'Password dimenticata',
            "requiredField": "Campo obbligatorio",
            "notValidEmail": "Email non valida",
            "profile": "Account",
            "accountGeneral": "Generale",
            "accountSecurity": "Sicurezza",
            "setAvatar": "Imposta avatar",
            "removeAvatar": "Rimuovi avatar",
            "profileT": "Profilo",

            "firstName": "Nome",
            "lastName": "Cognome",
            "save": "Salva",

            "changePwd": "Cambia password",
            "oldPwd": "Vecchia password",
            "password": "Password",
            "confirmPassword": "Conferma password",
            "pwdMismatch": "Le password devono coincidere",
            "pwdUpdated": "Password aggiornata con successo",
            "minLen7": "Lunghezza minima: 7 caratteri",

            "newUser": "Nuovo utente",
            "user_breadcrumb": "Nuovo utente",
            "usersT": "Utenti",
            "newUserTitle": "Informazioni login",
            "newUserTitle2": "Anagrafica",

            /*SETTINGS */
            "theme": "Tema",
            "settings": "Impostazioni",
            "saveSettings": "Salva Impostazioni",
            "rtl": "Cambia direzione testo",
            "responsiveFont": "Grande font dinamica",
            "responsiveFontDescription": "Dimensione font responsive",
            "compact": "Compatto",
            "compactDescription": "Larghezza fissa in alcune risoluzioni",
            "roundBorders": "Bordi rotondi",
            "roundBordersDescription": "Aumenta il raddio dei bordi",

            /* GENERAL */
            "back": "Indietro",
            "actions": "Azioni",

            /* SIDEBAR */
            "users": "users",
            "list": "Lista",
            "roles": "roles e permessi",

            /* DATE FILTER */
            "startDate": "Data Inizio",
            "endDate": "Data Fine",
            "today": "Oggi",
            "yesterday": "Ieri",
            "tomorrow": "Domani",
            "week": "Settimana",
            "month": "Mese",
            "lastMonth": "Mese Scorso",
            "nextMonth": "Mese Prossimo",

            /* TABLE */
            "of": "di",
            "tableNoData": "Nessun dato trovato",
            "select": "Seleziona",
            "to": "a",

            /* YEOMAN IT ANCHOR */
"report_update_save_btn": "Salva report",
"report_update_breadcrumb": "Aggiorna report",
"report_update_title": "report: Aggiorna",
"report_update": "Aggiorna report",
"report_create_save_btn": "Salva report",
"report_breadcrumb": "Nuovo report",
"report_title": "report: Nuovo",
"report_new": "Nuovo report",
"report_delete_button": "Elimina report",
"report_update_button": "Aggiorna report",
"report_new_button": "Nuovo report",
"report_page_header": "report",
        }
    },
    en: {
        translation: {
            'loginHeader': 'Welcome',
            'loginDescription': 'Log in on the internal platform',
            'loginBtn': 'Log In',
            'demoLabel': 'Use',
            'forgotPwd': 'Recover password',
            "requiredField": "Required field",
            "notValidEmail": "Not valid email",
            'logout': 'Logout',
            "profile": "Account",
            "accountGeneral": "Overview",
            "accountSecurity": "Security",
            "setAvatar": "Set avatar",
            "removeAvatar": "Remove avatar",
            "profileT": "Profile",
            "firstName": "First name",
            "lastName": "Last name",
            "save": "Save",

            "changePwd": "Change password",
            "oldPwd": "Old password",
            "password": "Password",
            "confirmPassword": "Confirm password",
            "pwdMismatch": "Password must be identical",
            "pwdUpdated": "Password updated",
            "minLen7": "Min len: 7 characters",

            "newUser": "New user",
            "user_breadcrumb": "New user",
            "usersT": "Users",
            "newUserTitle": "Login credentials",
            "newUserTitle2": "Overview",

            /*ROLES*/
            "role":"Roles",
            "policy":"Policy",
            "groups":"Groups",
            "newRole":"New role",
            "roleInfo":"Role Info",
            "newPolicy":"New policy",
            "newGroup":"New group",
            "policySettings":"Policy settings",
            "policySettings1":"Policy settings",

            /*SETTINGS */
            "theme": "Theme",
            "settings": "Settings",
            "saveSettings": "Save settings",
            "rtl": "Change text direction",
            "responsiveFont": "Responsive font sizes",
            "responsiveFontDescription": "Adjust font for small devices",
            "compact": "Compact",
            "compactDescription": "Fixed width on some screens",
            "roundBorders": "Rounded Corners",
            "roundBordersDescription": "Increase border radius",

            /* GENERAL */
            "back": "Back",
            "actions": "Actions",

            /* SIDEBAR */
            "users": "Users",
            "list": "List",
            "roles": "Roles and permissions",

            /* DATE FILTER */
            "startDate": "Start Date",
            "endDate": "End Date",
            "today": "Today",
            "yesterday": "Yesterday",
            "tomorrow": "Tomorrow",
            "week": "Week",
            "month": "Month",
            "lastMonth": "Last Month",
            "nextMonth": "Next Month",

            /* TABLE */
            "of": "of",
            "tableNoData": "No data was found",
            "select": "Select",
            "to": "a",

            /* YEOMAN EN ANCHOR */
"report_update_save_btn": "Save report",
"report_update_breadcrumb": "Update report",
"report_update_title": "report: Update",
"report_update": "Update report",
"report_create_save_btn": "Save report",
"report_breadcrumb": "New report",
"report_title": "report: New",
"report_new": "New report",
"report_delete_button": "Delete report",
"report_update_button": "Update report",
"report_new_button": "New report",
"report_page_header": "report",

        }
    },
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: window.localStorage.getItem('lang'),
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        }
    });
