// @ts-nocheck
import React, {useEffect, useState, Fragment} from 'react';
import {FC, ChangeEvent} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {useSnackbar} from 'notistack';

import {
    Box, Breadcrumbs,
    Card,
    IconButton,
    Container,
    Grid,
    Link,
    Tooltip,
    Typography, CircularProgress,
} from '@material-ui/core';
import {Table} from 'src/components/Table/Table'
import {Button} from 'src/components/Button/Button'

import Scrollbar from '../../material/Scrollbar';
import {Helmet} from "react-helmet-async";
import ChevronRightIcon from "../../icons/ChevronRight";
import PlusIcon from "../../icons/Plus";
import useSettings from "../../hooks/useSettings";
import {useSelector} from "../../store";
import {Resources, Utente} from "../../types/generated";
import PermissionGuard from "../../components/PermissionGuard/PermissionGuard";
import ArrowRightIcon from '../../icons/ArrowRight';
import PencilAltIcon from '../../icons/PencilAlt';
import {useTranslation} from "react-i18next";
import DeleteIcon from '@material-ui/icons/Delete';

import {readAll, deleteReport} from "./commands.report";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import GraphqlClient from "../../client/graphql.client";

const PageReport: FC = (props) => {
    const data = useSelector(state => state.report.data);
    const user = useSelector(state => (state as any).auth)
    const {t} = useTranslation();

    const {settings} = useSettings();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [loadingDownload, setLoadingDownload] = useState(false);
    const {...other} = props;

    useEffect(() => {
        readAll();
    }, []);

    const scaricaTemplate = async (report) => {
        try {
            setLoadingDownload(true);
            const res = await GraphqlClient.downloadReportTemplate({id: report.id});

            const linkSource = `data:application/odt;base64,${res.downloadReportTemplate.content}`;
            const downloadLink = document.createElement("a");
            const fileName = res.downloadReportTemplate.fileName;

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        } catch (e) {
            alert("Impossibile scaricare report");
            console.error(e);
        } finally {
            setLoadingDownload(false);
        }
    };


    const deleteEntry = async (id: string) => {
        try {
            let res = await deleteReport(id);
            readAll();
            enqueueSnackbar("Eliminato con successo", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'bottom'
                },
                variant: 'success'
            });
        } catch (e) {
            console.log('Error', e);
            enqueueSnackbar("Impossibile eliminare", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }
    }

    // @ts-ignore
    return (
        <>
            <Helmet>
                <title>Dashboard: report </title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 8
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                Report
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small"/>}
                                sx={{mt: 1}}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/"
                                    variant="subtitle2"
                                >
                                    Dashboard
                                </Link>
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    report
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item>
                            <Box sx={{m: -1}}>
                                <RouterLink to={{
                                    pathname: "/reports/new",
                                    state: {"ok": "ok"}
                                }} style={{textDecoration: 'none'}}>
                                    <Button
                                        color="primary"
                                        startIcon={<PlusIcon fontSize="small"/>}
                                        sx={{m: 1}}
                                        variant="contained"
                                        label={t("report_new")}
                                    />
                                </RouterLink>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{mt: 3}}>
                        <Card {...other}>
                            <Scrollbar>
                                <Box sx={{minWidth: 1200}}>
                                    <Table
                                        dark
                                        data={data}
                                        columns={
                                            [


                                                {"Header": "Nome", "accessor": "description"},

                                                {"Header": "Compagnia", "accessor": "company"},

                                                {
                                                    "Header": "Tipologia",
                                                    "accessor": "reportTypeDescription"
                                                },
                                                {
                                                    Header: 'Azioni',
                                                    accessor: 'azioni',
                                                    Cell: row => (
                                                        <>
                                                            {
                                                                loadingDownload ?
                                                                    <CircularProgress size={24} disableShrink/>
                                                                    :
                                                                    <Tooltip title={"Scarica template"}>
                                                                        <IconButton
                                                                            aria-label='Download'
                                                                            color="primary"
                                                                            onClick={() => scaricaTemplate(row.row.original)}
                                                                        >
                                                                            <CloudDownloadIcon fontSize="small"/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                            }
                                                            <Tooltip title={"Elimina"}>
                                                                <IconButton
                                                                    aria-label='Elimina'
                                                                    color="secondary"
                                                                    onClick={() => deleteEntry(row.row.original.id)}
                                                                >
                                                                    <DeleteIcon fontSize="small"/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </>
                                                    )
                                                },
                                            ]}
                                    />
                                </Box>
                            </Scrollbar>
                        </Card>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default PageReport;
