import React from 'react'
import { FC, useEffect, useState } from 'react';
import Button_MUI from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import IconButton_MUI from '@material-ui/core/IconButton';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import DeleteIcon from '@material-ui/icons/Delete';
import ButtonGroup_MUI from '@material-ui/core/ButtonGroup';


export interface ButtonProps{
  label?:string;
  size?: 'small' | 'medium' | 'large';
  color?: 'primary'|'secondary';
  variant?: "text" | "outlined" | "contained";
  onClick?: () => void;
  component?: any
  type?: string;
  disabled?: boolean;
  to?: any;
  startIcon?: any;
  sx?: any;
  className?: any;
}

export const Button: React.FC<ButtonProps> = ({
  label,
  color,
  size,
  variant,
  onClick,
  component,
  to,
  ...props
}) => {
  return (
    <Button_MUI variant={variant} color={color} size={size} onClick={onClick} component={component} to={to} {...props}>
      {label}
    </Button_MUI>

  );
};

export interface TextButtonProps{
  label?:string;
  size?: 'small' | 'medium' | 'large';
  color?: 'primary'|'secondary';
  variant?: "text" | "outlined" | "contained";
  onClick?: () => void;
  component?: any
  type?: string;
  disabled?: boolean;
  to?: any;
  startIcon?: any;
  sx?: any;
  href?: any;
  className?: any;
}

export const TextButton: FC<TextButtonProps> = ({
  color,
  label,
  disabled,
  href,
  size,
  ...props
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }));
  const classes = useStyles();

  if (disabled) {
    return (
      <div className={classes.root}>
        <Button_MUI disabled href={href} color={color} size={size} {...props}>{label}</Button_MUI>
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        <Button_MUI href={href} color={color} size={size} {...props}>{label}</Button_MUI>
      </div>
    );
  }
};

/* 
export const UploadButton: FC = ({
  label,
  color,
  size,
  accept_type,
  id,
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <input
        accept={accept_type}
        className={classes.input}
        id={id}
        multiple
        type="file"
      />
      <label htmlFor={id}>
        <Button_MUI variant="contained" color={color} size={size} component="span">
          {label}
        </Button_MUI>
      </label>
    </div>
  )
};
*/

/*
export const OutlinedButton: FC = ({
  color,
  label,
  disabled,
  href,
  size
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }));
  const classes = useStyles();

  if (disabled) {
    return (
      <div className={classes.root}>
        <Button_MUI variant="outlined" disabled href={href} color={color} size={size}>{label}</Button_MUI>
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        <Button_MUI variant="outlined" href={href} color={color} size={size}>{label}</Button_MUI>
      </div>
    );
  }
}

export const IconLabelButton: FC = ({
  color,
  label,
  size,
  action
}) => {
  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  }));
  const classes = useStyles();
  return (
    <div>
      <Button_MUI
        variant="contained"
        size={size}
        color={color}
        className={classes.button}
        startIcon={<SaveIcon />}
        onClick={action}
      >
        {label}
      </Button_MUI>
    </div>
  );
}

export const IconButton: FC = ({
  color,
  size,
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }));
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <IconButton_MUI
        size={size}
        color={color}
      >
        <AddShoppingCartIcon />
      </IconButton_MUI>
      <IconButton_MUI
        size={size}
        color={color}
      >
        <DeleteIcon />
      </IconButton_MUI>

    </div>
  );
}

export const ButtonGroup: FC = ({
  size,
  color,
  variant,
  label1,
  label2,
  label3,
  orientation
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }));
  const classes = useStyles();


    return (
      <div className={classes.root}>
        <ButtonGroup_MUI size={size} color={color} variant={variant} orientation={orientation}>
          <Button_MUI>{label1}</Button_MUI>
          <Button_MUI>{label2}</Button_MUI>
          <Button_MUI>{label3}</Button_MUI>
        </ButtonGroup_MUI>
      </div>
    )
  

} */