import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  useTable,
  usePagination,
  useFilters,
  useExpanded,
  useFlexLayout,
  useResizeColumns,
  useSortBy
} from 'react-table';
import { matchSorter } from 'match-sorter'
import moment from 'moment';
import MaUTable from '@material-ui/core/Table'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {
  Select,
  MenuItem,
  Chip,
  createStyles,
  IconButton,
  Theme,
  TextField,
  Button,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  Typography,
  Grid,
  Divider,
  makeStyles
} from '@material-ui/core';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ClearIcon from '@material-ui/icons/Clear';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import SortIcon from '@material-ui/icons/Sort';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import { useTheme } from '@material-ui/core/styles';


import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css';
import 'moment/locale/it'
import { useTranslation } from 'react-i18next';
import { settings } from 'nprogress';
import useSettings from 'src/hooks/useSettings';


const Styles = styled.div`  
.CalendarDay__selected_span {
  background: #4e75e1;
  color: white;
  border: 1px solid;
}


.CalendarDay__selected {
  background: #27293d;
  color: white;
}


.CalendarDay__selected:hover {
  background: white;
  color: black;
}


.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #4e75e1;
  color: #ffffff;
}

.CalendarDay__hovered_span_3 {
  color: #ffffff;
}

.DateRangePicker {
  border-radius: ${props => props.rounded}px;
  border: 1px solid #80808073 !important;
  width: 85%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}


.DateRangePicker:hover {
  border-color: black !important;
}


.DateRangePicker div {
  border-radius: 0.4285rem;


}

.DateRangePicker_picker {
  z-index: 99999 !important;

}

.DateRangePickerInput {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 0;
  border-radius: 0.4285rem;
  background-color: inherit;
  height: 38px;
}

.DateInput {
  margin: 0;
  padding: 0;
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  color: #ffffff;
  height: 100%;
  background-color: inherit;
}

.DateInput_input {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-weight: 400;
  font-size: 1rem;
  border: 0px;
  text-align: center;
  box-shadow: none;
  width: 100%;
  height: 100%;
  padding: 4px 0px 5px;
  border-radius: ${props => props.rounded}px;
  color: #919eab;
  text-decoration-color: #000000;
  background-color: inherit;
}

.DateInput_input::placeholder {
  color: #6b778c
}

.DateRangePickerInput__withBorder {
  color: #ffffff;
}

DateRangePicker_picker__fullScreenPortal {
  background: linear-gradient(0deg, #3358f4 0%, #1d8cf8 100%);
}

.DayPicker_transitionContainer {
  position: relative;
  overflow: hidden;
  border-radius: 3px;

}

.DateRangePickerInput_arrow {
  display: flex;
  align-items: center;
  color: grey;
  width: 28px
}

.DateRangePickerInput_arrow_svg {
  vertical-align: middle;
  height: 24px;
  width: 24px
}

.CalendarDay>td {
  height: 10px;
  width: 10px;
}
    .pagination {
      padding: 16px;
      width: 70%;
    }
    .filter {
      width: 90%;
      marginTop: 8px;
      padding: 5px;
    }
    .paginationSelect:before{
      border-bottom: 0px;
      transition: none;
      content: none;
    }
    .paginationSelect:after{
      border-bottom: 0px;
    }
    .selectmenu > div:focus{
      background-color: inherit !important;
    }

    .resizer {
      display: inline-block;
      background: none;
      width: 3px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      transform: translateX(50%);
      z-index: 1;
      ${'' /* prevents from scrolling while dragging on touch devices */}
      touch-action:none;

      &.isResizing {
        background: white;
      }
    }

    .resizer:hover{
      background: grey;
    }

   .iconSort{
     fill: lightgrey
   }

   .iconSort:hover{
     fill: grey
   }

  `
const DarkStyles = styled.div`  
  .CalendarDay__selected_span {
    background: #4e75e1;
    color: white;
    border: 1px solid;
  }

  .CalendarDay__selected {
    background: #27293d;
    color: white;
  }

  .CalendarDay__selected:hover {
    background: white;
    color: black;
  }

  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: #4e75e1;
    color: #ffffff;
  }

  .CalendarDay__hovered_span_3 {
    color: #ffffff;
  }

  .DateRangePicker {
    border-radius: ${props => props.rounded}px;
    border: 1px solid rgb(79, 86, 95) !important;
    width: 85%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }



  .DateRangePicker:hover {
    border-color: #ffffff !important;
  }

  .DateRangePicker_picker {
    z-index: 99999 !important;
  }

  .DateRangePickerInput {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 0;
    background-color: inherit;
    height: 38px;
  }

  .DateInput {
    margin: 0;
    padding: 0;
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    color: #ffffff;
    height: 100%;
    background-color: inherit;
  }

  .DateInput_input {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-weight: 400;
    font-size: 1rem;
    border: 0px;
    text-align: center;
    box-shadow: none;
    width: 100%;
    height: 100%;
    padding: 4px 0px 5px;
    border-radius: ${props => props.rounded}px;
    color: #919eab;
    text-decoration-color: #000000;
    background-color: inherit;
  }

  .DateInput_input::placeholder {
    color: #919eab
  }

  .DateRangePickerInput__withBorder {
    color: #ffffff;
  }



  DateRangePicker_picker__fullScreenPortal {
    background: linear-gradient(0deg, #3358f4 0%, #1d8cf8 100%);
  }

  .DayPicker_transitionContainer {
    position: relative;
    overflow: hidden;
    border-radius: 3px;

  }

  .DateRangePickerInput_arrow {
    display: flex;
    align-items: center;
    color: #ffffff52 !important;
    width: 28px
  }

  .DateRangePickerInput_arrow_svg {
    vertical-align: middle;
    height: 24px;
    width: 24px
  }

  .CalendarDay>td {
    height: 10px;
    width: 10px;
  }

  .pagination {
    padding: 16px;
    display: flex;   
  }
  
  .paginationSelect{
    color: white;
    border: none;
  }

  .paginationSelect:before{
    border-bottom: 0px;
    transition: none;
    content: none;
  }
  
  .paginationSelect:after{
    border-bottom: 0px;
  }
  
  .filter {
    width: 90%;
    marginTop: 8px;
    padding: 5px;   
  }
 
  .icon {
    fill: white;
  }

  .paginationSelect > svg {
    fill: white;
  }

  .selectmenu > div {
    color: white;
  }
  
  .selectmenu > svg {
    fill: white;
  }  

  .resizer {
    display: inline-block;
    background: none;
    width: 3px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    ${'' /* prevents from scrolling while dragging on touch devices */}
    touch-action:none;

    &.isResizing {
      background: white;
    }      
  }

  .resizer:hover{
    background: white;
  }

 .iconSort{
   fill: #9c9c9c
 }

 .iconSort:hover{
   fill: white
 }
  
`

function DefaultColumnFilter({
  column: { filterValue, setFilter },
}) {
  const { t } = useTranslation()
  return (
    <TextField
      fullWidth
      size='small'
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={t("search")}
      variant='outlined'
      InputLabelProps={{
        style: {
          paddingTop: '2px',
          paddingLeft: '7px'
        }
      }}
    />
  )
}

function isSameDay(a, b) {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  // Compare least significant, most likely to change units first
  // Moment's isSame clones moment inputs and is a tad slow
  return a.date() === b.date()
    && a.month() === b.month()
    && a.year() === b.year();
}

export function DatePickerRangeFilter({
  column: {
    filterValue = [],
    preFilteredRows,
    setFilter,
    id
  }
}) {

  const { t } = useTranslation()
  const { i18n } = useTranslation()
  new Date();
  var momDate = moment();
  var firstDay = moment().startOf('month');
  var lastDay = moment().endOf('month');
  var firstWeekDay = moment().startOf('week');
  var lastWeekDay = moment().endOf('week');
  var previousFirstDay = moment().startOf('month').subtract(1, 'month');
  var previousLastDay = moment().subtract(1, 'month').endOf('month');
  var nextFirstDay = moment().startOf('month').add(1, 'month');
  var nextLastDay = moment().add(1, 'month').endOf('month');
  moment().startOf('year');
  moment().endOf('year');
  var yesterday = moment().subtract(1, 'days');
  var tomorrow = moment().add(1, 'days');

  var presetOpt = {
    startDate: null,
    endDate: null,
    presets: [
      {
        label: t("today"),
        startDate: momDate,
        endDate: momDate
      },
      {
        label: t("yesterday"),
        startDate: yesterday,
        endDate: yesterday
      },
      {
        label: t("tomorrow"),
        startDate: tomorrow,
        endDate: tomorrow
      },
      {
        label: t("week"),
        startDate: firstWeekDay,
        endDate: lastWeekDay
      },
      {
        label: t("month"),
        startDate: firstDay,
        endDate: lastDay
      },
      {
        label: t("lastMonth"),
        startDate: previousFirstDay,
        endDate: previousLastDay
      },
      {
        label: t("nextMonth"),
        startDate: nextFirstDay,
        endDate: nextLastDay
      },
    ],
  };

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [focusedInput, setFocusedInput] = useState(false);

  useEffect(() => {
    moment.locale(i18n.language)
  }, [t])

  const renderDatePresets = () => {
    return (
      <div>
        {presetOpt.presets.map(({ label, startDate, endDate }) => {
          const isSelected = isSameDay(presetOpt.startDate, startDate) && isSameDay(presetOpt.endDate, endDate);
          let className = '';
          if (!isSelected) {
            className = "btn-simple";
          }
          return (
            <Button size="small" className={className} color="primary" variant='text'
              key={label}
              onClick={() => {
                onDatesChange({ startDate: startDate, endDate: endDate });
                setFocusedInput(false);
              }}>
              {label}
            </Button>
          );
        })}
      </div>
    );
  }

  const onDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    /* setFilter({
      start: '0000/00/00',
      end: '9999/99/99'
    }) */
    if (startDate != null && endDate != null) {
      setFilter({
        start: moment(startDate).format('YYYY/MM/DD'),
        end: moment(endDate).format('YYYY/MM/DD')
      });
    }

  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around'
        }}
      >
        <DateRangePicker
          startDatePlaceholderText={t("startDate")}
          endDatePlaceholderText={t("endDate")}
          startDate={startDate}
          onDatesChange={onDatesChange}
          startDateId={"startDate"}
          endDate={endDate}
          renderCalendarInfo={renderDatePresets}
          preset
          minimumNights={0}
          hideKeyboardShortcutsPanel
          focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={focusedInput => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
          isOutsideRange={() => false}
          customArrowIcon={<ArrowForwardIosIcon style={{ padding: '4px' }} />}
        />
        <IconButton
          onClick={
            () => {
              setStartDate(undefined)
              setEndDate(undefined);
              setFilter({
                start: '0000/00/00',
                end: '9999/99/99'
              })
            }
          }
          size="small"
          className='iconFilterDate'
        >
          <ClearIcon />
        </IconButton>
      </div>
    </>
  )
}

function formatDate(input) {
  var datePart = input.match(/\d+/g),
    year = datePart[0],
    month = datePart[1],
    day = datePart[2];

  return day + '/' + month + '/' + year;
}

function compareDate(start, end, checkdate) {

  let D_start = start.split("/");
  let D_end = end.split("/");
  let D_check = checkdate.split("/");

  //console.log("sono oltre",D_start, " ",D_end," ",D_check )

  var d_start = new Date(D_start[2], parseInt(D_start[1]) - 1, D_start[0]);
  var d_end = new Date(D_end[2], parseInt(D_end[1]) - 1, D_end[0]);
  var d_check = new Date(D_check[2], parseInt(D_check[1]) - 1, D_check[0]);

  if (d_check >= d_start && d_check <= d_end) {
    return true;
  } else {
    return false;
  }
}

export function DatePickerRangeFilterMethod(rows, id, filterValue) {
  var start = formatDate(filterValue.start);
  var end = formatDate(filterValue.end);

  return rows.filter(row => {
    if (Number.isInteger(row.original[id]))
      return compareDate(start, end, moment.unix(row.original[id]).format("DD/MM/YYYY"))

    return compareDate(start, end, moment(new Date(row.original[id])).format("DD/MM/YYYY"))
  })
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  }),
);

export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const classes = useStyles();
  const options = React.useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      if (row.values[id]) {
        options.add(row.values[id])
      }
    })
    return [...options.values()]
  }, [id, preFilteredRows])


  const [selezionati, setSelezionati] = React.useState<string[]>([]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelezionati(event.target.value as string[]);
  };
  // Render a multi-select box
  return (
    <>
      <Select
        multiple
        fullWidth
        size="small"
        variant="outlined"
        value={selezionati}
        onChange={handleChange}
        onClose={() => {
          setFilter(selezionati)
        }}
      >
        <MenuItem value={null || undefined} disabled></MenuItem>
        {options.map((option, i) => (
          <MenuItem key={i} value={option.toString()}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </>
  )
}

export function SelectColumnFilterMethod(rows, id, filterValue) {
  let righeFiltrate = [];
  if (filterValue.length) {
    for (let i = 0; i < filterValue.length; i++) {
      for (let j = 0; j < rows.length; j++) {
        if (filterValue[i] === rows[j].values[id]) {
          righeFiltrate.push(rows[j])
        }
      }
    }
    return righeFiltrate;
  } else {
    return rows
  }
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
export function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
    preFilteredRows.forEach(row => {
      min = Math.min(row.values[id], min)
      max = Math.max(row.values[id], max)
    })
    return [min, max]
  }, [id, preFilteredRows])

  const { t } = useTranslation()
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <TextField
        value={filterValue[0] || ''}
        size="small"
        type="number"
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
        }}
        label={`Min (${min})`}
        sx={{ mr: '5px' }}
      />
      {t("to")}
      <TextField
        value={filterValue[1] || ''}
        size="small"
        type="number"
        onChange={e => {
          const val = e.target.value
          setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
        }}
        label={`Max (${max})`}
        sx={{ ml: '5px'}}
      />
    </div>
  )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  // @ts-ignore
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

const getStyles = (props, align = 'left') => [
  props,
  {
    style: {
      justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
      alignItems: 'flex-start',
      display: 'flex',
    },
  },
]

function TableStruct({
  columns,
  data,
  renderRowSubComponent,
  FiltersRemote,
  disableFilters,
  disableSorting,
  idcolonna,
}) {

  

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      array: (rows, id, filterValue) => {
        // @ts-ignore
        return matchSorter(rows, filterValue, { keys: [row => row.values[id]?.map(rule => rule?.description)] })
      },
      data: (rows, id, filterValue) => {
        // @ts-ignore
        return matchSorter(rows, filterValue, { keys: [row => moment(new Date(row.values[id])).format("DD/MM/YYYY")] })
      },
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
      minWidth: 50,
      width: 250,
      maxWidth: 600,
    }),
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    rows,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    visibleColumns,
    // Get the state from the instance
    state: { pageIndex, pageSize, expanded },
  } = useTable(
    {
      autoResetExpanded: false,
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10, autoResetExpanded: false, ...idcolonna && {sortBy: [{id: idcolonna, desc: true}]} }, // Pass our hoisted table state
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      autoResetPage: false,
      autoResetFilters: false
    },
    useResizeColumns,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useFlexLayout,
  )

  const { t } = useTranslation()
  let filtri = FiltersRemote;

  return (
    <React.Fragment>

      <MaUTable {...getTableProps()} style={{ minHeight: '600px' }}>
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <TableCell {...column.getHeaderProps()}>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    {column.render('Header')}
                    {column.canSort && !disableSorting &&
                      <div {...column.getHeaderProps(column.getSortByToggleProps())}
                        style={{ width: '10%', display: 'flex', justifyContent: 'center' }}>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? <ArrowDownwardIcon />
                            : <ArrowUpwardIcon />
                          : <SortIcon
                            className='iconSort'
                          />
                        }
                      </div>
                    }
                  </div>
                  {filtri ? <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '10px' }}>{filtri[index]}</div> :
                    disableFilters ? null : <div style={{ width: '100%', display: 'flex' }}>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        marginTop: '5px'
                      }}>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    </div>
                  }
                  <div {...column.getResizerProps()}
                    className={`resizer ${column.isResizing ? 'isResizing' : ''
                      }`}>
                  </div>

                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>

        <TableBody {...getTableBodyProps()}>

          {
            page.length > 0 ?
              page.map((row, i) => {
                prepareRow(row)
                let rowProps = row.getRowProps()
                if (row.original.background){
                  rowProps.style = { ...rowProps.style,backgroundColor: row.original.background }
                }

                return (
                  <React.Fragment>
                    <TableRow  {...rowProps}>
                      {row.cells.map(cell => {
                        return (
                          <TableCell {...cell.getCellProps()} >
                            <div style={{
                              display: "flex",
                              alignItems: "center",
                              height: "100%"
                            }}>{cell.render('Cell')}</div>
                          </TableCell>
                        )
                      })}
                    </TableRow>

                    {row.isExpanded ? (
                      <TableRow {...row.getRowProps()}>
                        <TableCell colSpan={visibleColumns.length}>
                          {renderRowSubComponent({ row })}
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </React.Fragment>
                )
              }) : (
                <div style={{
                  height: '400px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <Typography
                    variant='body1'
                  >
                    {t("tableNoData")}
                  </Typography>

                </div>
              )
          }
        </TableBody>

        <TableFooter>
          <Divider />
          <TableRow>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
              <Select
                className='paginationSelect'
                value={pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value))
                }}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <MenuItem key={pageSize} value={pageSize}>{pageSize}</MenuItem>
                ))}
              </Select>

              <span style={{ padding: '10px' }}>
                <Typography variant='body2'>
                  {page.length >= 10 ? (page.length * (pageIndex + 1)) : page.length} {t("of")} {rows.length}
                </Typography>
              </span>


              <IconButton
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                aria-label="delete"
                size="small"
              >
                <ArrowBackIosIcon className='icon' fontSize="inherit" />
              </IconButton>
              <IconButton onClick={() => nextPage()}
                disabled={!canNextPage} aria-label="delete" size="small">
                <ArrowForwardIosIcon className='icon' fontSize="inherit" />
              </IconButton>
            </div>
          </TableRow>
        </TableFooter>
      </MaUTable>
    </React.Fragment >
  )
}

export interface TableProps {
  Data?: any;
  Columns?: any;
  RenderRowSubComponent?: any;
  remoteFilter?: any;
  disableFilters?: boolean;
  disableSorting?: boolean;
  idDefaultColumnSort?: string;
}


export const Table: React.FC<TableProps> = ({
  Data,
  Columns,
  RenderRowSubComponent,
  remoteFilter,
  disableFilters,
  disableSorting,
  idDefaultColumnSort,
  ...props
}) => {
  const theme = useTheme();

  switch (theme.palette.mode) {
    case "dark":
      return (
        <DarkStyles rounded={theme.shape.borderRadius}>
          <TableStruct
            disableSorting={disableSorting ? true : false}
            disableFilters={disableFilters ? true : false}
            FiltersRemote={remoteFilter}
            columns={Columns}
            data={Data}
            renderRowSubComponent={RenderRowSubComponent}
            idcolonna={idDefaultColumnSort}
            {...props}
          />
        </DarkStyles>
      )
    case "light":
      return (
        <Styles rounded={theme.shape.borderRadius}>
          <TableStruct
            disableSorting={disableSorting ? true : false}
            disableFilters={disableFilters ? true : false}
            FiltersRemote={remoteFilter}
            columns={Columns}
            data={Data}
            renderRowSubComponent={RenderRowSubComponent}
            idcolonna={idDefaultColumnSort}
            {...props}
          />
        </Styles>
      )
  }
}