import type { FC } from "react";
import { useEffect, useState, ChangeEvent } from "react";
import useSettings from '../../hooks/useSettings';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
    Box, Breadcrumbs,
    Container,
    Divider,
    Grid,
    Link,
    Typography,
    Tabs, Tab, Button, IconButton, Card, CardHeader, CardContent,
} from '@material-ui/core';
import ChevronRightIcon from "../../icons/ChevronRight";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Credentials, ElencoUltimeNote, PraticheList, Richiesta, RichiestaSingola, RispostaSingolaRichiesta } from "src/types/generated";
import GraphqlClient from "src/client/graphql.client";




const DettaglioNota: FC = (props) => {
    const { settings } = useSettings();
    const [currentTab, setCurrentTab] = useState<string>('richiesta');

    const { ...other } = props;
    const params = useParams();
    const id = String(params.id)

    const [loadingData, setLoadingData] = useState(false);

    const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
        setCurrentTab(value);
    };

    /* let credentials: Credentials = {
        UserId: "61E54AD3-8AF6-4289-821B-023ED2EC9239",
        SessionID: "61E54AD3-8AF6-4289-821B-023ED2EC9239"
    } */

    let listaNote = [];

    const [notaTrovata, setNotaTrovata] = useState(
        {
            notaId: '',
            dataInserimento: '',
            codice: '',
            tipoNota: '',
            mittenteId: '',
            fullnameMittente: '',
            testo: ''
        }
    )

    useEffect(() => {
        const loadNote = async () => {
            try {
                const res = await GraphqlClient.ottieniDashboardGenerale();
                listaNote = res.ottieniDashboardGenerale.elencoUltimeNote;
                cercaNota(id);

            } catch (error) {
                return { loaded: false, message: error.toString() }
            }
        };
        loadNote();
    }, []);

    const cercaNota = (id) => {
        listaNote.map(
            nota => {
                if (nota.notaId === id) {
                    setNotaTrovata(nota)
                }
            }
        )
    }

    


    return (
        <>
            <Helmet>
                <title>Dettaglio Nota</title>
            </Helmet>

            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 8
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Typography
                        color="textPrimary"
                        variant="h5"
                    >
                        Dettaglio Nota
                    </Typography>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >

                        <Grid item>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/"
                                    variant="subtitle2"
                                >
                                    Dashboard
                                </Link>
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/note"
                                    variant="subtitle2"
                                >
                                    Note
                                </Link>
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    Nota
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item>
                            <Box sx={{ m: -1 }}>
                                <Button
                                    color="primary"
                                    component={RouterLink}
                                    startIcon={<ArrowBackIcon fontSize="small" />}
                                    sx={{ mt: 1 }}
                                    to="/note"
                                    variant="outlined"
                                >
                                    Indietro
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 3 }}>
                        <Card>
                            <CardHeader
                                title={"Nota " + notaTrovata.notaId}
                            />
                            <Divider />
                            <CardContent>
                                <Typography style={{ display: 'inline-block', width: '35%', }} gutterBottom variant="subtitle1" component="h2">
                                    Id
                                </Typography>
                                <Typography style={{ display: 'inline-block', width: '65%', }} variant="body2" component="p">
                                    {notaTrovata.notaId}
                                </Typography>

                                <Divider />

                                <Typography style={{ display: 'inline-block', width: '35%', }} gutterBottom variant="subtitle1" component="h2">
                                    Data Inserimento
                                </Typography>
                                <Typography style={{ display: 'inline-block', width: '65%', }} variant="body2" component="p">
                                    {notaTrovata.dataInserimento}
                                </Typography>

                                <Divider />

                                <Typography style={{ display: 'inline-block', width: '35%', }} gutterBottom variant="subtitle1" component="h2">
                                    Codice
                                </Typography>
                                <Typography style={{ display: 'inline-block', width: '65%', }} variant="body2" component="p">
                                    {notaTrovata.codice}
                                </Typography>

                                <Divider />

                                <Typography style={{ display: 'inline-block', width: '35%', }} gutterBottom variant="subtitle1" component="h2">
                                    Tipo
                                </Typography>
                                <Typography style={{ display: 'inline-block', width: '65%', }} variant="body2" component="p">
                                    {notaTrovata.tipoNota}
                                </Typography>

                                <Divider />

                                <Typography style={{ display: 'inline-block', width: '35%', }} gutterBottom variant="subtitle1" component="h2">
                                    Mittente
                                </Typography>
                                <Typography style={{ display: 'inline-block', width: '65%', }} variant="body2" component="p">
                                    {notaTrovata.fullnameMittente}
                                </Typography>

                                <Divider />

                                <Typography style={{ display: 'inline-block', width: '35%', }} gutterBottom variant="subtitle1" component="h2">
                                    Id mittente
                                </Typography>
                                <Typography style={{ display: 'inline-block', width: '65%'}} variant="body2" component="p">
                                    {notaTrovata.mittenteId}
                                </Typography>

                                <Divider />

                                <Typography style={{ display: 'inline-block', width: '35%', }} gutterBottom variant="subtitle1" component="h2">
                                    Testo
                                </Typography>
                                <Typography style={{ display: 'inline-block', width: '65%' }}  variant="body2" component="p">
                                    {notaTrovata.testo}
                                </Typography>

                            </CardContent>
                        </Card>
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default DettaglioNota
