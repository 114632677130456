import { FC, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { DashboardCommissionale, ListPraticheMaturateMeseFiliali, MeseValore, } from 'src/types/generated';
import moment from 'moment';
import { createNota } from 'src/graphql/generated/mutations';
import { useSelector as useReduxSelector } from '../../../store'



const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

interface AndamentoMarginiProps {
    dati: DashboardCommissionale
    tipoUtente: string
}

const ChartAndamentoMargini: FC<AndamentoMarginiProps> = (props) => {
    const theme = useTheme();
    const classes = useStyles();

    const filiali = useReduxSelector(state => state.configuration.itemsForSelects?.filiale) || [];


    const [listaUtente, setListaUtente] = useState<MeseValore[]>(null)
    const [listaAmministratore, setListaAmministratore] = useState<MeseValore[]>(null)
    const [listaFiliale, setListaFiliale] = useState<any>(null)

    const [listData, setListData] = useState([])

    useEffect(() => {
        if (props.tipoUtente === 'Collaboratore') {
            setListaUtente(props.dati.listPraticheMaturateMeseUtente as MeseValore[])
        } else if (props.tipoUtente === 'Amministratore societa di mediazione') {
            setListaAmministratore(props.dati.listPraticheMaturateMeseSocietaMediazione as MeseValore[])
        } else if (props.tipoUtente === 'Responsabile di filiale') {
            setListaFiliale(props.dati.listPraticheMaturateMeseFiliali as ListPraticheMaturateMeseFiliali[])
        }
    }, [props.tipoUtente])

    const cercaFiliale = (id) => {
        let filiale = '';
        filiali.map(item => { if (id === item.codice) filiale = item.description })
        return filiale
    }

    const riordinaMesi = () => {
        if (props.tipoUtente === 'Collaboratore') {
            let app = []
            for (let i = 0; i < 12; i++) {
                app.push({ mese: i, valore: 0 })
            }
            listaUtente?.map(
                itemMese => {
                    let data = new Date(itemMese.mese)
                    let mese = data.getMonth()
                    app[mese].valore += itemMese.valore
                }
            )
            setListData(app)
            return
        } else if (props.tipoUtente === 'Amministratore societa di mediazione') {
            let app = []
            for (let i = 0; i < 12; i++) {
                app.push({ mese: i, valore: 0 })
            }
            listaAmministratore?.map(
                itemMese => {
                    let data = new Date(itemMese.mese)
                    let mese = data.getMonth()
                    app[mese].valore += itemMese.valore
                }
            )
            setListData(app)
            return
        } else if (props.tipoUtente === 'Responsabile di filiale') {
            let datiFiliali = []
            listaFiliale?.map(
                itemFiliale => {
                    let datiSingolaFiliale = [];
                    for (let i = 0; i < 12; i++) {
                        datiSingolaFiliale.push({ mese: i, valore: 0 })
                    }
                    itemFiliale.listValoreMese?.map(
                        itemMese => {
                            let data = new Date(itemMese.mese)
                            let mese = data.getMonth()
                            datiSingolaFiliale[mese].valore += itemMese.valore
                        }
                    )

                    datiFiliali.push({ filiale: cercaFiliale(itemFiliale.filialeId), mesi: datiSingolaFiliale })
                }
            )

            setListData(datiFiliali);
            return
        }
    }

    useEffect(() => { riordinaMesi() }, [listaUtente, listaFiliale, listaAmministratore])

    //useEffect(() => console.log(listData), [listData])

    const listMesi = ["GEN", "FEB", "MAR", "APR", "MAG", "GIU", "LUG", "AGO", "SET", "OTT", "NOV", "DIC"]

    let valori = props.tipoUtente === 'Responsabile di filiale' ?
        listData?.map(itemFiliale => {
            return {
                name: itemFiliale.filiale,
                data: itemFiliale.mesi.map(
                    m => m.valore
                )
            }
        }) : [{
            name: props.tipoUtente,
            data: listData?.map(item => item.valore)
        }]


    /* let valori = [{
        name: props.tipoUtente,
        data: listData?.map(item => item.valore)
    }] */

    const chart = {
        options: {
            chart: {
                background: 'transparent',
                stacked: false,
                toolbar: {
                    show: false
                },
                zoom: false
            },
            colors: ['#ffb547', '#7783DB', '#64B6F7'],
            dataLabels: {
                enabled: false
            },
            fill: {
                type: 'solid',
                opacity: 0
            },
            grid: {
                borderColor: theme.palette.divider
            },
            markers: {
                strokeColors: theme.palette.background.paper,
                size: 6
            },
            stroke: {
                curve: 'straight',
                width: 2
            },
            theme: {
                mode: theme.palette.mode
            },
            tooltip: {
                theme: theme.palette.mode
            },
            xaxis: {
                axisBorder: {
                    color: theme.palette.divider,
                    show: true
                },
                axisTicks: {
                    color: theme.palette.divider,
                    show: true
                },
                categories:
                    listData?.map(item => listMesi[item.mese]
                    )
            }
        },
        series: valori
    };

    return (
        <Card {...props}>
            <CardContent>
                <Typography
                    variant="h5"
                    component="h2"
                >
                    Andamento margini provigionali
                </Typography>
                <Typography
                    className={classes.pos}
                    color="textSecondary"
                >
                    {/*  Periodo analizzato: [
                    {moment(listData[0]?.mese).format("MMM YYYY")}
                    {" - "}
                    {moment(listData[listData.length - 1]?.mese).format("MMM YYYY")}
                    ] */}
                </Typography>
                <Chart
                    height="360"
                    type="area"
                    {...chart}
                />
            </CardContent>
        </Card>
    );
};


export default ChartAndamentoMargini;
