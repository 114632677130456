import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
    Autocomplete,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    CardContentClasses,
    CardHeader,
    Container,
    Grid,
    IconButton,
    Link,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import {
    FinanceCostBreakdown,
    FinanceOverview,
    FinanceIncrementalSales,
    FinanceProfitableProducts,
    FinanceSalesByContinent,

} from '../../material/dashboard/finance';
import useSettings from '../../hooks/useSettings';
import ChevronRightIcon from '../../icons/ChevronRight';
import RefreshIcon from '@material-ui/icons/Refresh';


import AddIcon from '@material-ui/icons/Add';
import { useSelector as useReduxSelector } from '../../store'

import { loadDashboardCommissionale } from './commands.dashboardCommissionale';
import { Credentials, DashboardCommissionale } from 'src/types/generated';
import ChartAndamentoMargini from './components/ChartAndamentoMargini';
import ChartAndamentoRegionale from './components/ChartAndamentoRegionale';

import ChartVariazioneRedditivita from './components/ChartVariazioneRedditivita';
import ChartOverview from './components/ChartOVerview';
import { Table, NumberRangeColumnFilter } from 'src/components/Table/Table';
import Scrollbar from 'src/material/Scrollbar';
import ChartClusterClientela from './components/ChartClusterClientela';
import { NoUndefinedVariablesRule } from 'graphql';

//MOCK DATA
import responsabile from "./resp.json"
import collaboratore from "./collab.json"
import amministratore from "./amm.json"


const PaginaDashboardCommissionale: FC = () => {
    const { settings } = useSettings();
    //const user = useSelector(state => (state as any).auth);

    const state = useReduxSelector(state => (state.dashboardCommissionale.data as DashboardCommissionale))
    //const state = amministratore as DashboardCommissionale
    const [tipoUtente, setTipoUtente] = useState<string>("")

    useEffect(() => { loadDashboardCommissionale() }, [])

    useEffect(() => {
        if (!state.listPraticheMaturateFiliali && !state.praticheMaturateSocieta) {
            setTipoUtente("Collaboratore")
            return
        }
        else if (!state.listPraticheMaturateClusterSocieta && state.listPraticheMaturateFiliali) {
            setTipoUtente("Responsabile di filiale")
            return
        } else if (state.listPraticheMaturateClusterSocieta) {
            setTipoUtente("Amministratore societa di mediazione")
            return
        } else {
            setTipoUtente('invalid user')
        }
    }, [state]);


    return (
        <>
            <Helmet>
                <title>Dashboard </title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 8
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                Dashboard Commissionale per: {tipoUtente}
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/"
                                    variant="subtitle2"
                                >
                                    Dashboard
                                </Link>
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    Dashboard Commissionale
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid>
                            <Tooltip title={"Refresh Dashboard"}>
                                <IconButton >
                                    <RefreshIcon fontSize="large" />
                                </IconButton>
                            </Tooltip>

                        </Grid>

                    </Grid>
                    <Box sx={{ mt: 3 }}>
                        <Grid
                            container
                            spacing={3}
                        >
                            <Grid item xs={12} >
                                <ChartOverview dati={state} tipoUtente={tipoUtente} />
                            </Grid>
                            <Grid item xs={8} >
                                <ChartAndamentoMargini dati={state} tipoUtente={tipoUtente} />
                            </Grid>
                            <Grid item xs={4} >
                                <ChartClusterClientela dati={state} tipoUtente={tipoUtente} />
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box >
        </>
    )
        ;
};

export default PaginaDashboardCommissionale;

