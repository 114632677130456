import {createSlice} from '@reduxjs/toolkit'

const configurationSlice = createSlice({
    name: 'configuration',
    initialState: {} as any,
    reducers: {
        read: (state, action) => {
            const {prodotti, durata, items} = action.payload;
            state.prodotti = prodotti;
            state.durate = durata;

            state.items = items;

            // Trasformo gli items in un oggetto più pratico
            let itemsMap = {};
            for (const item of items) {
                itemsMap[item.tipo] = itemsMap[item.tipo] || [];
                itemsMap[item.tipo][item.codice] = item.descrizione;
            }

            let itemsForSelects = {};
            for (const item of items) {
                itemsForSelects[item.tipo] = itemsForSelects[item.tipo] || [];
                itemsForSelects[item.tipo].push({
                    description: item.descrizione,
                    codice: item.codice
                });
            }

            state.itemsForSelects = itemsForSelects;
            state.itemsMap = itemsMap;
        },
    },
})

export const actions = configurationSlice.actions;
export default configurationSlice.reducer;
