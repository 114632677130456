const casbinjs = require("casbin.js");

let authorizer = undefined;

export const SetPermissions = async (perms) => {
    authorizer = new casbinjs.Authorizer("manual")
    await authorizer.setPermission(perms);
    console.log('LOADING PERMS', perms);
    console.log('LOADED PERMS', authorizer.getPermission());
}

export const Can = async (action, resource) => {
    if (!authorizer) return false;

    let result = await authorizer.can(action, resource);
    return result;
}
