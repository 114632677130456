import React from 'react';
import type { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField_MUI from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import AccountCircle from '@material-ui/icons/AccountCircle';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import clsx from 'clsx';
import Input from '@material-ui/core/Input';



export interface TFieldProps {
    label?: string;
    size?: 'small' | 'medium';
    variant?: "filled" | "outlined";
    required?;
    input_id?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value?: string;
    fullWidth?;
    margin?: any;
    style?: any;
    error?: any;
    helperText?: any;
    onBlur?: any;
    autoFocus?;
    type?: string;
    InputProps?: any;
}

export const TextField: React.FC<TFieldProps> = ({
    input_id,
    label,
    size,
    variant,
    required,
    onChange,
    value,
    ...props
}) => {

    const useStyles = makeStyles((theme) => ({
        root: {
            '& > *': {
                margin: theme.spacing(1),
                //width: '25ch',
            },
        },
    }));
    const classes = useStyles();

    if (required) {
        return (
            <form className={classes.root} noValidate autoComplete="off">
                <TextField_MUI required id={input_id} size={size} label={label} variant={variant} onChange={onChange} value={value} {...props} />
            </form>
        );
    } else {
        return (
            <form className={classes.root} noValidate autoComplete="off">
                <TextField_MUI id={input_id} size={size} label={label} variant={variant} onChange={onChange} value={value} {...props} />
            </form>
        );
    }


}

export const TextFieldPassword: React.FC<TFieldProps> = ({
    input_id,
    label,
    size,
    variant,
    helperText,
    ...props
}) => {

    const [values, setValues] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        margin: {
            margin: theme.spacing(1),
        },
        withoutLabel: {
            marginTop: theme.spacing(3),
        },
        textField: {
            //width: '25ch',
        },
    }));
    const classes = useStyles();



    if (variant == 'filled') {
        return (
            <FormControl className={clsx(classes.margin, classes.textField)} size={size}>
                <InputLabel htmlFor={input_id}>{label}</InputLabel>
                <Input
                    {...props}
                    id={input_id}
                    type={values.showPassword ? 'text' : 'password'}
                    value={values.password}
                    onChange={handleChange('password')}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }

                />
            </FormControl>
        );
    } else {
        const lab_lenght = label.length;
        return (

            <FormControl className={clsx(classes.margin, classes.textField)} size={size} variant={variant}>
                <InputLabel htmlFor={input_id}>{label}</InputLabel>
                <OutlinedInput
                    {...props}
                    id={input_id}
                    type={values.showPassword ? 'text' : 'password'}
                    value={values.password}
                    onChange={handleChange('password')}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }

                    labelWidth={lab_lenght * 9}
                />
            </FormControl>
        );
    }


}

/*
export const TextFieldNumber: FC = ({
    input_id,
    label,
    size,
    variant
}) => {

    const useStyles = makeStyles((theme) => ({
        root: {
            '& > *': {
                margin: theme.spacing(1),
                width: '25ch',
            },
        },
    }));
    const classes = useStyles();

    return (
        <form className={classes.root} noValidate autoComplete="off">
            <TextField_MUI
                id={input_id}
                size={size}
                label={label}
                type="number"
                variant={variant}
                InputLabelProps={{
                    shrink: true,
                }} />
        </form>
    );
}

export const TextFieldMultiline: FC = ({
    input_id,
    label,
    variant,
    row
}) => {
    const [value, setValue] = React.useState('Controlled');
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                '& .MuiTextField-root': {
                    margin: theme.spacing(1),
                    width: '25ch',
                },
            },
        }),
    );
    const classes = useStyles();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    return (
        <form className={classes.root} noValidate autoComplete="off">
            <div>
                <TextField_MUI
                    id={input_id}
                    label={label}
                    multiline
                    //rowsMax={row}
                    value={value}
                    onChange={handleChange}
                    variant={variant}
                />
            </div>
        </form>
    );


} */