import {useEffect, useState} from 'react';
import type {FC} from 'react';
import {Link as RouterLink, useNavigate, useLocation, useParams} from 'react-router-dom';
import * as Yup from 'yup';
import {Formik, ErrorMessage} from 'formik';
import {useSnackbar} from 'notistack';
import {
    Box, Breadcrumbs,
    Switch,
    Card,
    CardContent,
    CardHeader,
    Checkbox, Container,
    FormControlLabel,
    FormHelperText,
    Autocomplete,
    Grid, Link,
    /* TextField, */
    Typography
} from '@material-ui/core';
import {useTranslation} from "react-i18next";

import useSettings from "../../hooks/useSettings";
import {Helmet} from "react-helmet-async";
import ChevronRightIcon from "../../icons/ChevronRight";
import ArrowLeftIcon from "../../icons/ArrowLeft";
import {createReport} from "./commands.report";
import {FilePond, registerPlugin} from 'react-filepond'
import 'filepond/dist/filepond.min.css'

import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';

/*****************************************/
import Button_MUI from '@material-ui/core/Button';
import {Button} from '../../components/Button/Button'
import {TextField} from '../../components/TextField/TextField'
/*****************************************/
// filepond plugin
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginFileEncode);

const NewReport: FC = (props) => {
    const navigate = useNavigate();
    const {settings} = useSettings();
    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation();
    const [file, setFile] = useState([]);
    const [reportType, setReportType] = useState({} as any);

    return (
        <>
            <Helmet>
                <title>{t("report_title")} </title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 8
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                {t("report_new")}
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small"/>}
                                sx={{mt: 1}}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/dashboard"
                                    variant="subtitle2"
                                >
                                    Dashboard
                                </Link>
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/reports"
                                    variant="subtitle2"
                                >
                                    Report
                                </Link>

                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    {t("report_breadcrumb")}
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item>
                            <Box sx={{m: -1}}>
                                <Button
                                    color="primary"
                                    component={RouterLink}
                                    startIcon={<ArrowLeftIcon fontSize="small"/>}
                                    sx={{mt: 1}}
                                    to="/reports"
                                    variant="outlined"
                                    label={t("back")}
                                />

                            </Box>
                        </Grid>
                    </Grid>

                    <Box sx={{mt: 3}}>
                        <Formik
                            initialValues={{
                                form_description: '',
                                form_reportType: '0',
                                form_reportTypeDescription: '',
                            }}

                            onSubmit={async (values, {
                                setErrors,
                                setStatus,
                                setSubmitting
                            }): Promise<void> => {
                                setSubmitting(true);
                                if (file.length === 0) {
                                    alert("Nessun file")
                                    return
                                }

                                let {form_description, form_reportType, form_reportTypeDescription} = values;

                                const res = await createReport(
                                    {
                                        description: form_description,
                                        report: file[0].getFileEncodeBase64String(),
                                        reportType: parseInt(form_reportType),
                                        reportTypeDescription: form_reportTypeDescription,
                                        fileName: file[0].filename,
                                    }
                                );

                                if (res.error && res.error == true) {
                                    setStatus({success: false});
                                    setSubmitting(false);
                                    enqueueSnackbar(res.message, {
                                        anchorOrigin: {
                                            horizontal: 'right',
                                            vertical: 'top'
                                        },
                                        variant: 'error'
                                    });
                                    return;
                                }

                                setStatus({success: true});
                                setSubmitting(false);
                                navigate('/reports');
                            }}
                        >
                            {({
                                  errors,
                                  handleBlur,
                                  handleChange,
                                  handleSubmit,
                                  isSubmitting,
                                  setFieldValue,
                                  touched,
                                  values
                              }): JSX.Element => (
                                <form
                                    onSubmit={handleSubmit}
                                    {...props}
                                >
                                    <Box sx={{p: 3}}>

                                        <Grid
                                            container
                                            spacing={3}
                                        >
                                            <Grid
                                                item
                                                md={12}
                                                xs={12}
                                            >
                                                <Card>
                                                    <CardContent>
                                                        <CardHeader title="Form"/>
                                                        <Box sx={{mt: 2}}>
                                                            <TextField
                                                                required
                                                                error={Boolean(touched.form_description && errors.form_description)}
                                                                fullWidth
                                                                helperText={touched.form_description && errors.form_description}
                                                                label="Nome"
                                                                input_id="form_description"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.form_description}
                                                                variant="outlined"
                                                            />
                                                        </Box>

                                                        <Box>
                                                            <Autocomplete
                                                                id="tipoNota"
                                                                options={[{value: 20, description: "Precontrattuale banca erogante da firmare"}]}
                                                                onChange={(_: any, newValue: any) => {
                                                                    //console.log(newValue)
                                                                    setFieldValue("form_reportType", newValue.value);
                                                                    setFieldValue("form_reportTypeDescription", newValue.description);
                                                                    setReportType(newValue);
                                                                }}
                                                                getOptionLabel={(option: any) => option.description}
                                                                renderInput={(params) => <TextField {...params}
                                                                                                    fullWidth
                                                                                                    label="Tipologia report"
                                                                                                    value={reportType.description}
                                                                                                    variant="outlined"
                                                                />
                                                                }
                                                            />
                                                        </Box>

                                                        <Box sx={{mt: 2}}>
                                                            <FilePond
                                                                files={file}
                                                                allowMultiple={false}
                                                                onupdatefiles={setFile}
                                                                name="docReddituali"
/*
                                                                acceptedFileTypes={['application/odt','.odt']}
*/
                                                                labelIdle='Trascina e lascia il file o <span class="filepond--label-action">Cerca</span>'
                                                                //required={true}
                                                            />
                                                        </Box>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                mt: 3
                                            }}
                                        >
                                            <Button
                                                color="primary"
                                                disabled={isSubmitting}
                                                type="submit"
                                                variant="contained"
                                                label={t("report_create_save_btn")}
                                            />
                                        </Box>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default NewReport;
