// @ts-nocheck
import React, {useEffect, useState, Fragment} from 'react';
import type {FC, ChangeEvent} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import {useSnackbar} from 'notistack';

import {
    Box, Breadcrumbs,
    Card,
    Tooltip,
    Container,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    /* TextField, */ Typography, CircularProgress, Toolbar, Avatar
} from '@material-ui/core';
import {Table} from 'src/components/Table/Table'


import ArrowRightIcon from '../../icons/ArrowRight';
import PencilAltIcon from '../../icons/PencilAlt';
import SearchIcon from '../../icons/Search';
import Scrollbar from '../../material/Scrollbar';
import {Helmet} from "react-helmet-async";
import ChevronRightIcon from "../../icons/ChevronRight";
import PlusIcon from "../../icons/Plus";
import useSettings from "../../hooks/useSettings";
import {useSelector} from "../../store";
import {Resources, Utente} from "../../types/generated";
import {readAll} from "./commands.user";


/*****************************************/
import Button_MUI from '@material-ui/core/Button';
import {Button} from '../../components/Button/Button'
import {TextField, TextFieldPassword} from '../../components/TextField/TextField'
import {impersonificate} from "../login/commands.login";
import PermissionGuard from "../../components/PermissionGuard/PermissionGuard";
import {useTranslation} from "react-i18next";
/*****************************************/
const wait = (time: number): Promise<void> => new Promise((res) => setTimeout(res, time));

const PageUser: FC = (props) => {
    const users = useSelector(state => state.users.data);
    const user = useSelector(state => (state as any).auth);
    const {t} = useTranslation();
    const {settings} = useSettings();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const {...other} = props;
    const [query, setQuery] = useState<string>('');

    const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setQuery(event.target.value);
    };

    useEffect(() => {
        readAll();
    }, [])


    const impersonifica = async (utente) => {
        const key = enqueueSnackbar(`Impersonificazione di ${utente} in corso..`, {
            anchorOrigin: {
                horizontal: 'right',
                vertical: 'bottom'
            },
            variant: 'info',
            persist: true,
            action: (key) => (<Fragment>
                <CircularProgress style={{color: "white"}} size={24}/>
            </Fragment>)
        });

        try {
            await impersonificate(utente);
            closeSnackbar(key);
        } catch (e) {
            closeSnackbar(key);
            enqueueSnackbar(`Impossibile procedere con l'impersonificazione`, {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'bottom'
                },
                variant: 'error'
            });
        }


    }

    // Usually query is done on backend with indexing solutions
    // @ts-ignore
    return (
        <>
            <Helmet>
                <title>Dashboard: t("usersT") </title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 8
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                {t("usersT")}
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small"/>}
                                sx={{mt: 1}}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/"
                                    variant="subtitle2"
                                >
                                    Dashboard
                                </Link>
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    users
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                        <PermissionGuard action={'allow'} resource={'users'}>
                            <Grid item>
                                <Box sx={{m: -1}}>
                                    <RouterLink to={{
                                        pathname: "/users/new",
                                        state: {"ok": "ok"}
                                    }} style={{textDecoration: 'none'}}>
                                        <Button
                                            color="primary"
                                            startIcon={<PlusIcon fontSize="small"/>}
                                            sx={{m: 1}}
                                            variant="contained"
                                            label={t("newUser")}
                                        />
                                    </RouterLink>
                                </Box>
                            </Grid>
                        </PermissionGuard>
                    </Grid>
                    <Box sx={{mt: 3}}>
                        <Card {...other}>
                            <Scrollbar>
                                <Box sx={{minWidth: 1200}}>
                                    <Table
                                        dark
                                        data={users}
                                        columns={
                                            [
                                                {
                                                    Header: '',
                                                    width: 20,
                                                    accessor: 'avatar',
                                                    disableFilters: true,
                                                    resizable: false,
                                                    Cell: row => (<Avatar
                                                        src={row.value}
                                                        sx={{
                                                            height: 45,
                                                            width: 45
                                                        }}
                                                    />)
                                                },
                                                {
                                                    Header: 'Email',
                                                    accessor: 'email',
                                                },
                                                {
                                                    Header: 'Nome',
                                                    accessor: 'firstName',
                                                },
                                                {
                                                    Header: 'Cognome',
                                                    accessor: 'lastName',
                                                },
                                                {
                                                    Header: t("actions"),
                                                    accessor: 'azioni',
                                                    Cell: row => (
                                                        <>
                                                            <PermissionGuard action={'allow'}
                                                                             resource={Resources.Users}>
                                                                <IconButton component={RouterLink}
                                                                            to={`/users/modifica/${row.row.values.email}`}>
                                                                    <PencilAltIcon fontSize="small"/>
                                                                </IconButton>
                                                                {
                                                                    !user?.impersonificated &&
                                                                    <Tooltip title={"Impersonifica utente"}>
                                                                        <IconButton
                                                                            onClick={() => impersonifica(row.row.values.email)}>
                                                                            <SupervisedUserCircleIcon fontSize="small"/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                }
                                                            </PermissionGuard>
                                                            <IconButton>
                                                                <ArrowRightIcon fontSize="small"/>
                                                            </IconButton>
                                                        </>
                                                    )
                                                },
                                            ]}
                                    />
                                </Box>
                            </Scrollbar>
                        </Card>
                    </Box>
                </Container>
            </Box>
        </>
    )
        ;
};

export default PageUser;
