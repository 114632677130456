import { Box, Breadcrumbs, Button, Card, Chip, ChipClassKey, Container, Grid, IconButton, Link, Tooltip, Typography } from "@material-ui/core";
import { Link as RouterLink } from 'react-router-dom';
import { FC, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import useSettings from "src/hooks/useSettings";
import { useSelector as useReduxSelector } from '../../store'

import ChevronRightIcon from "../../icons/ChevronRight";
import { Table, SelectColumnFilter, SelectColumnFilterMethod, DatePickerRangeFilter, DatePickerRangeFilterMethod } from '../../components/Table/Table'
import Scrollbar from "src/material/Scrollbar";
import { Credentials, Filters, FiltersRic } from "src/types/generated";
import moment from "moment";
import { padding } from "@material-ui/system";
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import ArrowRightIcon from '../../icons/ArrowRight';
import { loadNote } from "./commands.note";

const PaginaNote: FC = (props) => {

    const state = useReduxSelector(state => state.note)
    const { settings } = useSettings();

    const { ...other } = props;

    /* let credentials: Credentials = {
        UserId: "7B6E6734-7206-445B-9852-8A1940751B3D",
        SessionID: "1655b9f1-1890-4ad6-bd2e-d7a26bc9db52"
    } */
    

    useEffect(() => {
       loadNote();
    }, [])

    return (

        <>
            <Helmet>
                <title>Note</title>
            </Helmet>

            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 8
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                Note
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/"
                                    variant="subtitle2"
                                >
                                    Dashboard
                                </Link>
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    Note
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item>
                            <Box sx={{ m: -1 }}>
                                <Button
                                    color="primary"
                                    component={RouterLink}
                                    startIcon={<AddIcon fontSize="small" />}
                                    sx={{ mt: 1 }}
                                    to="/note/nuovaNota"
                                    variant="contained"
                                >
                                    Nuova Nota
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 3 }}>
                        <Card {...other}>
                            <Scrollbar>
                                <Box sx={{ minWidth: 1200 }}>
                                    <Table
                                        Data={state.data}
                                        Columns={
                                            [
                                                
                                                {
                                                    Header: 'Tipologia',
                                                    accessor: 'tipoNota',
                                                    width: 100,
                                                    filter: SelectColumnFilterMethod,
                                                    Filter: SelectColumnFilter
                                                },
                                                {
                                                    Header: 'Data',
                                                    accessor: 'dataInserimento',
                                                    Cell: (row) => (
                                                        moment(new Date(row.value)).format('DD MMM YYYY')
                                                    ),
                                                    filter: DatePickerRangeFilterMethod,
                                                    Filter: DatePickerRangeFilter,   
                                                    width: 300                                              
                                                },
                                                {
                                                    Header: 'Mittente',
                                                    accessor: 'fullnameMittente',
                                                    Cell: (row) => {
                                                        let str = row.value;
                                                        let splitStr = str.toLowerCase().split(' ');
                                                        for (let i = 0; i < splitStr.length; i++) {
                                                            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
                                                        }
                                                        return splitStr.join(' ')
                                                    }
                                                },
                                                {
                                                    Header: 'Testo',
                                                    accessor: 'testo',
                                                    width: 500,
                                                    Cell: (row) => {
                                                        if (row.value.length >= 80) {
                                                            return row.value.substring(0, 80) + '...'
                                                        } else {
                                                            return row.value
                                                        }
                                                    }
                                                },
                                                {
                                                    Header: 'Azioni',
                                                    Cell: (row) => {
                                                        return (
                                                            <>
                                                                <Tooltip title={"Dettaglio"}>
                                                                    <IconButton
                                                                        aria-label='Dettagli'
                                                                        component={RouterLink}
                                                                        to={`/note/dettaglioNote/${row.row.original.notaId}`}
                                                                    >
                                                                        <ArrowRightIcon fontSize="small" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </>
                                                        )
                                                    },
                                                    width: 100,                                                   
                                                }                                                                                            
                                            ]
                                        }
                                    />
                                </Box>
                            </Scrollbar>
                        </Card>
                    </Box>


                </Container>

            </Box>

        </>
    )
}

export default PaginaNote;