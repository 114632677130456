import store from "../../store";

import {actions} from './redux.role';
import GraphqlClient from "../../client/graphql.client";

export const readAll = async () => {
    try {
        const res = await GraphqlClient.roles({});
        store.dispatch(actions.readedAll(res.roles));
        return {data: res.roles}
    } catch (error) {
        store.dispatch(actions.readedAll([]));
        return {error: true, message: JSON.parse(JSON.stringify(error)).response.error}
    }
};

export const createRole = async (name: string, description: string) => {
    try {
        const res = await GraphqlClient.createRole({name, description});
        store.dispatch(actions.created(res.createRole));
        return {data: res.createRole}
    } catch (error) {
        return {error: true, message: error.toString()}
    }
};

