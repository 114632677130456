import store from "../../store";

import {actions} from './redux.note';
import GraphqlClient from "../../client/graphql.client";
import { Credentials } from "src/types/generated";



export const loadNote = async () => {
    try {
      const res = await GraphqlClient.ottieniDashboardGenerale();
      store.dispatch(actions.load(res.ottieniDashboardGenerale.elencoUltimeNote))
    } catch (error) {
      return { loaded: false, message: error.toString() }
    }
  };
