import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useNavigate, useLocation, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { object, array, string, number } from "yup";
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
    Autocomplete,
    Box, Breadcrumbs,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox, Container,
    FormControlLabel,
    FormHelperText,
    Grid, IconButton, Link,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import useSettings from "../../hooks/useSettings";
import { Helmet } from "react-helmet-async";
import ChevronRightIcon from "../../icons/ChevronRight";
import ArrowLeftIcon from "../../icons/ArrowLeft";
import { createNota } from 'src/graphql/generated/mutations';

import { useSelector as useReduxSelector } from '../../store'

import GraphqlClient from "../../client/graphql.client";
import _ from "lodash";
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Credentials, Entity, Note } from 'src/types/generated';
import { OptionTypes } from 'filepond';


const NuovaNota: FC = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const { settings } = useSettings();
    const { enqueueSnackbar } = useSnackbar();

    const configuration = useReduxSelector(state => state.configuration)

    const [notes, setNotes] = useState([{
        index: 0,
        testo: ''
    }]);
    const [tipoNota, setTipoNota] = useState(
        {
            tipo: '',
            id: ''
        }
    )

    const addEmptyNota = () => {
        let newIndex = 0;
        if (notes.length > 0) {
            newIndex =
                _.maxBy(notes, function (w) {
                    return w.index;
                }).index + 1;
        }

        const newNota = {
            index: newIndex,
            testo: ''
        };

        setNotes([...notes, newNota]);
    };

    const removeNota = (noteToRemove) => {
        const noteList = notes.filter(
            (note) => note.index != noteToRemove.index
        );
        setNotes([...noteList]);
    };

    const updateNote = (updatedNote) => {
        let newNote = [...notes];
        const index = _.findIndex(notes, {
            index: updatedNote.index,
        });
        newNote.splice(index, 1, updatedNote);
        setNotes([...newNote]);
    };

    const checkIfEmpty = () => {
        let check = false;
        notes.map(
            elem => {
                if (elem.testo.length === 0) {
                    check = true
                }
            }
        )
        if (check)
            return false
        return true
    }

    const inserisciNota = async () => {
        if (!checkIfEmpty()) {
            enqueueSnackbar("Tutti i campi sono obbligatori", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
            return;
        }
        try {
            let elencoNoteDaInserire: Note[] = [];
            notes.map(
                elem => {
                    let singolaNota: Note = {
                        Nota: elem.testo
                    }
                    elencoNoteDaInserire.push(singolaNota)
                }
            )
            //console.log("queste sono le note da inserire-> ", entity)

            let Entity: Entity = {
                Tipo: 'richiestaPreventivi',
                Id: '4'
            };
            let notesMock: Note[] = [
                {
                    Nota: "test",
                }
            ]

            await GraphqlClient.createNota({
                entity: Entity,
                notes: elencoNoteDaInserire
            });
            enqueueSnackbar("Note salvate", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'success'
            });
            navigate('/note');

        } catch (e) {
            console.error(e);
            enqueueSnackbar("Impossibile inserire le note", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }
        return
    }

    const arrayTipoNota = (configuration?.itemsForSelects && configuration?.itemsForSelects["tipoNota"]) || [];
    //console.log(tipoNota)

    return (
        <>
            <Helmet>
                <title>Nuova Nota</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 8
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                Nuova nota
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/dashboard"
                                    variant="subtitle2"
                                >
                                    Dashboard
                                </Link>
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/note"
                                    variant="subtitle2"
                                >
                                    Note
                                </Link>
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                >
                                    Nuova nota
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item>
                            <Box sx={{ m: -1 }}>
                                <Button
                                    color="primary"
                                    component={RouterLink}
                                    startIcon={<ArrowLeftIcon fontSize="small" />}
                                    sx={{ mt: 1 }}
                                    to="/note"
                                    variant="outlined"
                                >
                                    Indietro
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>



                    <Box sx={{ p: 5 }}>


                        <Card>
                            <CardHeader title="Informazioni nota" />
                            <CardContent>
                                <Box >
                                    <Typography variant="h6" component="h1">
                                        Tipo Nota:  Richiesta Preventivo
                                    </Typography>

                                    {/*  <Autocomplete
                                        id="tipoNota"
                                        options={arrayTipoNota}
                                        onChange={(_: any, newValue: any) => {
                                            //console.log(newValue)
                                            setTipoNota({
                                                tipo: newValue.description,
                                                id: newValue.codice
                                            });
                                        }}
                                        getOptionLabel={(option: any) => option.description}
                                        renderInput={(params) => <TextField {...params}
                                            fullWidth
                                            label="Tipo nota"
                                            id="tipoNota"
                                            value={tipoNota}
                                            variant="outlined"
                                        />
                                        }
                                    /> */}
                                </Box>
                                <Box sx={{ p: 5 }}>
                                    {notes.map((note) => (
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                paddingTop: "5px",
                                                paddingBottom: "5px",
                                                flexDirection: 'row',

                                            }}
                                        >
                                            <TextField
                                                fullWidth
                                                label="Testo nota"
                                                id="note"
                                                onChange={(e) => {
                                                    note.testo = e.target.value;
                                                    updateNote(note);
                                                }}
                                                value={note.testo}
                                                variant="outlined"
                                                multiline
                                                rows={3}
                                            />

                                            <IconButton onClick={() => { removeNota(note) }} aria-label="delete">
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </div>
                                    ))}
                                </Box>
                                <Tooltip title="Aggiungi nota">
                                    <IconButton onClick={() => { addEmptyNota() }} aria-label="delete">
                                        <AddCircleIcon color="primary" fontSize="large" />
                                    </IconButton>
                                </Tooltip>
                            </CardContent>

                        </Card>


                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                mt: 3
                            }}
                        >
                            <Button
                                color="primary"
                                onClick={inserisciNota}
                                variant="contained"
                            >
                                Inserisci nota
                            </Button>
                        </Box>
                    </Box>



                </Container>
            </Box>
        </>
    );
};

export default NuovaNota;
