import { FC, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { DashboardCommissionale, StructClusterQuantitaValore, ListPraticheMaturateClusterFiliale } from 'src/types/generated';
import { useSelector as useReduxSelector } from '../../../store'



interface ClusterClientelaProps {
    dati: DashboardCommissionale
    tipoUtente: string
}


const ChartClusterClientela: FC<ClusterClientelaProps> = (props) => {
    const theme = useTheme();

    const tipoImpiego = useReduxSelector(state => state.configuration?.itemsForSelects?.tipoImpiego) || [] as string[];

    const [dati, setDati] = useState([
        { cluster: 0, quantita: 0, valore: 0 },
        { cluster: 1, quantita: 0, valore: 0 },
        { cluster: 2, quantita: 0, valore: 0 }
    ] as any)
    /* let dati = [
        { cluster: 0, quantita: null, valore: null },
        { cluster: 1, quantita: 0, valore: 0 },
        { cluster: 2, quantita: 0, valore: 0 }] as any */

    const sommaCluster = (lista) => {
        let clusters = [
            { cluster: 0, quantita: 0, valore: 0 },
            { cluster: 1, quantita: 0, valore: 0 },
            { cluster: 2, quantita: 0, valore: 0 }
        ]
        lista.map(
            itemFiliale => {
                itemFiliale.listValoreCluster.map(
                    itemCluster => {
                        clusters[itemCluster.cluster].quantita += itemCluster.quantita
                        clusters[itemCluster.cluster].valore += itemCluster.valore
                    }
                )
            }
        )
        return clusters
    }

    useEffect(() => {
        if (props.tipoUtente === 'Responsabile di filiale') {
            if (props.dati.listPraticheMaturateClusterFiliali) {
                //dati = sommaCluster(props.dati.listPraticheMaturateClusterFiliali) as any
                setDati(sommaCluster(props.dati.listPraticheMaturateClusterFiliali))
            }
        } else if (props.tipoUtente === 'Collaboratore') {
            if (props.dati.listPraticheMaturateClusterUtente) {
                setDati(props.dati.listPraticheMaturateClusterUtente as any)
                //dati = props.dati.listPraticheMaturateClusterUtente as any
            }
        } else if (props.tipoUtente === 'Amministratore societa di mediazione') {
            if (props.dati.listPraticheMaturateClusterSocieta) {
                setDati(props.dati.listPraticheMaturateClusterSocieta as any)
                //dati = props.dati.listPraticheMaturateClusterSocieta as any
            }
        }
    }, [props.tipoUtente])



    const cercaImpiego = (id) => {
        let impiego = ''
        impiego = tipoImpiego[id]?.description
        //console.log(impiego)
        return impiego
    }

    let data = {
        series: [
            {
                color: '#FFB547',
                valore: dati[0].valore,
                quantita: dati[0].quantita,
                label: cercaImpiego(0)
            },
            {
                color: '#7BC67E',
                valore: dati[1].valore,
                quantita: dati[1].quantita,
                label: cercaImpiego(1)
            },
            {
                color: '#7783DB',
                valore: dati[2].valore,
                quantita: dati[2].quantita,
                label: cercaImpiego(2)
            }
        ]
    }


    const chart = {
        options: {
            chart: {
                background: 'transparent',
                stacked: false,
                toolbar: {
                    show: false
                },
                zoom: false
            },
            colors: data.series.map((item) => item.color),
            dataLabels: {
                enabled: false
            },
            labels: data.series.map((item) => item.label),
            legend: {
                show: false
            },
            stroke: {
                colors: theme.palette.background.paper,
                width: 1
            },
            theme: {
                mode: theme.palette.mode
            }
        },
        series: data.series.map((item) => item.valore)
    };



    return (
        <Card {...props}>
            <CardHeader title={"Valore margini cluster "} />
            <CardContent>
                {/*  <Chart
                    height="300"
                    type="donut"
                    {...chart}
                /> */}
                {
                    data.series.map((item, key) => (
                        <Box
                            key={item.label}
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                p: 1
                            }}
                        >
                            <Box
                                sx={{
                                    backgroundColor: item.color,
                                    borderRadius: '50%',
                                    height: 8,
                                    width: 8
                                }}
                            />
                            <Typography
                                color="textPrimary"
                                sx={{ ml: 2 }}
                                variant="subtitle2"
                            >
                                {item.label}
                            </Typography>
                            <Box sx={{ flexGrow: 1 }} />
                            <Typography
                                color="textSecondary"
                                variant="subtitle2"
                                sx={{mr:1}}
                            >
                               
                                {" " + new Intl.NumberFormat('it-IT', {
                                    style: 'currency',
                                    currency: 'eur',
                                }).format(item.valore) + " "}
                            </Typography>
                            <Typography
                                color="textSecondary"
                                variant="subtitle2"
                            >
                                quantita:
                                {" " + new Intl.NumberFormat('it-IT', {}).format(item.quantita)}
                            </Typography>

                        </Box>
                    ))}
            </CardContent>
        </Card>
    );
};

export default ChartClusterClientela;
