import store from "../../store";

import {actions} from './redux.richieste';
import GraphqlClient from "../../client/graphql.client";
import {Credentials, Exact, Filters, FiltersRic, InputRichiestaPreventivi, InputRichiestaPreventiviConDocumentoSingolo} from "src/types/generated";


export const loadRichieste = async (filters: FiltersRic) => {
    try {
        const res = await GraphqlClient.ottieniRichieste({filters});
        store.dispatch(actions.load(res.ottieniRichieste.richiesteList))
        return {data: res.ottieniRichieste};
    } catch (error) {
        console.error(error);
        return {loaded: false, message: error.toString()}
    }
};

export const creaRichiesta = async (input: InputRichiestaPreventivi) => {
    try {
        const res = await GraphqlClient.richediPreventivi({input});
        return {data: res.richediPreventivi};
    } catch (error) {
        console.error(error);
        return {error: true, message: error.response.errors}
    }
}


export const creaRichiestaConDocumentoSingolo = async (input: InputRichiestaPreventiviConDocumentoSingolo) => {
    try {
        const res = await GraphqlClient.richiediPreventiviConDocumentoSingolo({input});
        return {data: res.richiediPreventiviConDocumentoSingolo};
    } catch (error) {
        console.error(error);
        return {error: true, message: error.response.errors}
    }
}