import { FC, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { Box, Grid, Typography, Card, Badge, Chip } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { DashboardCommissionale, StructQuantitaValore } from 'src/types/generated';

interface ChartLineProps {
  data: any
}


const ChartLine: FC<ChartLineProps> = (props) => {
  const theme = useTheme();


  const chart = {
    options: {
      chart: {
        background: 'transparent',
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        }
      },
      colors: ['#7783DB'],
      dataLabels: {
        enabled: false
      },
      grid: {
        show: false
      },
      stroke: {
        curve: 'smooth',
        width: 3
      },
      theme: {
        mode: theme.palette.mode
      },
      tooltip: {
        enabled: false
      },
      xaxis: {
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        show: false
      }
    },
    series: [
      {
        data: props.data
      }
    ]
  };

  return (
    <Chart
      type="line"
      {...chart}
    />
  );
};


interface ClusterClientelaProps {
  dati: DashboardCommissionale
  tipoUtente: string
}
const ChartOverview: FC<ClusterClientelaProps> = (props) => {

  let dati = {
    praticheMaturate: null,
    montanteLordoMaturato: null,
    praticheTransitorie: null,
    montanteLordoTransitorio: null,
    pratichePotenziali: null,
    montanteLordoPotenziale: null,
  }

  const somma = (lista) => {
    let elem = {
      quantita: 0,
      valore: 0
    }
    lista.map(item => {
      elem.quantita += item.quantita
      elem.valore += item.valore
    })
    return elem

  }

  const aggiornaDatiFiliale = () => {
    dati.praticheMaturate = somma(props.dati.listPraticheMaturateFiliali)
    dati.montanteLordoMaturato = somma(props.dati.listMontanteLordoMaturatoFiliali)
    dati.praticheTransitorie = somma(props.dati.listPraticheTransitorieFiliali)
    dati.montanteLordoTransitorio = somma(props.dati.listMontanteLordoTransitorioFiliali)
    dati.pratichePotenziali = somma(props.dati.listPratichePotenzialiFiliali)
    dati.montanteLordoPotenziale = somma(props.dati.listMontanteLordoPotenzialeFiliali)
  }


  if (props.tipoUtente === 'Responsabile di filiale') {
    aggiornaDatiFiliale()
  } else if (props.tipoUtente === 'Collaboratore') {
    dati.praticheMaturate = props.dati.praticheMaturateUtente
    dati.montanteLordoMaturato = props.dati.montanteLordoMaturatoUtente
    dati.praticheTransitorie = props.dati.praticheTransitorieUtente
    dati.montanteLordoTransitorio = props.dati.montanteLordoTransitorieUtente
    dati.pratichePotenziali = props.dati.pratichePotenzialiUtente
    dati.montanteLordoPotenziale = props.dati.montanteLordoPotenzialeUtente
  } else if (props.tipoUtente === 'Amministratore societa di mediazione') {
    dati.praticheMaturate = props.dati.praticheMaturateSocieta
    dati.montanteLordoMaturato = props.dati.montanteLordoMaturatoSocieta
    dati.praticheTransitorie = props.dati.praticheTransitorioSocieta
    dati.montanteLordoTransitorio = props.dati.montanteLordoTransitorioSocieta
    dati.pratichePotenziali = props.dati.pratichePotenzialeSocieta
    dati.montanteLordoPotenziale = props.dati.montanteLordoPotenzialeSocieta
  }

  return (

    < Card {...props}>
      <Grid container>
        <Grid
          item
          md={4}
          xs={12}
          sx={{
            alignItems: 'center',
            borderRight: (theme) => (
              {
                md: `1px solid ${theme.palette.divider}`
              }
            ),
            borderBottom: (theme) => (
              {
                md: 'none',
                xs: `1px solid ${theme.palette.divider}`
              }
            ),
            display: 'flex',
            justifyContent: 'space-between',
            p: 3
          }}
        >
          <div>
            <Typography
              color="textSecondary"
              variant="overline"
            >
              PRATICHE MATURATE
            </Typography>
            <Chip
              label={dati.praticheMaturate?.quantita}
              color='primary'
              style={{
                marginLeft: '10px'
              }}
            />
            <Typography
              color="textPrimary"
              variant="h5"
            >
              {new Intl.NumberFormat('it-IT', {
                style: 'currency',
                currency: 'eur',
              }).format(dati.praticheMaturate?.valore)}
            </Typography>
            <Typography
              color="textSecondary"
              variant="caption"
            >
              <p>Volume montante lordo (ML):
                <Chip
                  style={{ marginLeft: '5px' }}
                  variant='outlined'
                  color='default'
                  label={new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'eur', }).format(dati.montanteLordoMaturato?.valore)}
                />
              </p>
            </Typography>
            {/* <Typography
              color="red"
              variant="caption"
            >
              <p>[ Somma di montante Lordo liquidato ]</p>
              <p>[ Conteggio di montante Lordo liquidato ]</p>
              <p>[ Somma di montante Lordo liquidato agenzia ]</p>
            </Typography> */}
          </div>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              height: 54,
              width: 177
            }}
          >
            <ChartLine data={[10, 15, 20, 10]} />
          </Box>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          sx={{
            alignItems: 'center',
            borderRight: (theme) => (
              {
                md: `1px solid ${theme.palette.divider}`
              }
            ),
            borderBottom: (theme) => (
              {
                xs: `1px solid ${theme.palette.divider}`,
                md: 'none'
              }
            ),
            display: 'flex',
            justifyContent: 'space-between',
            p: 3
          }}
        >
          <div>
            <Typography
              color="textSecondary"
              variant="overline"
            >
              PRATICHE TRANSITORIE
            </Typography>
            <Chip
              label={dati.praticheTransitorie?.quantita}
              color='primary'
              style={{
                marginLeft: '10px'
              }}
            />
            <Typography
              color="textPrimary"
              variant="h5"
            >
              {new Intl.NumberFormat('it-IT', {
                style: 'currency',
                currency: 'eur',
              }).format(dati.praticheTransitorie?.valore)}
            </Typography>

            <Typography
              color="textSecondary"
              variant="caption"
            >
              <p>Volume montante lordo (ML):
                <Chip
                  style={{ marginLeft: '5px' }}
                  variant='outlined'
                  color='default'
                  label={new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'eur', }).format(dati.montanteLordoTransitorio?.valore)}
                />
              </p>
            </Typography>
            {/*             <Typography
              color="red"
              variant="caption"
            >
              <p>[ Somma di montante Lordo transitorio ]</p>
              <p>[ Conteggio di montante Lordo transitorio ]</p>
              <p>[ Somma di montante Lordo transitorio agenzia ]</p>
            </Typography> */}
          </div>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              height: 54,
              width: 177
            }}
          >
            <ChartLine data={[10, 15, 20, 10]} />
          </Box>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            p: 3
          }}
        >
          <div>
            <Typography
              color="textSecondary"
              variant="overline"
            >
              PRATICHE POTENZIALI
            </Typography>
            <Chip
              label={dati.pratichePotenziali?.quantita}
              color='primary'
              style={{
                marginLeft: '10px'
              }}
            />
            <Typography
              color="textPrimary"
              variant="h5"
            >
              {new Intl.NumberFormat('it-IT', {
                style: 'currency',
                currency: 'eur',
              }).format(dati.pratichePotenziali?.valore)}
            </Typography>

            <Typography
              color="textSecondary"
              variant="caption"
            >
              <p>
                Volume montante lordo (ML):
                <Chip
                  style={{ marginLeft: '5px' }}
                  variant='outlined'
                  color='default'
                  label={new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'eur', }).format(dati.montanteLordoPotenziale?.valore)}
                />
              </p>
            </Typography>
            {/*  <Typography
              color="red"
              variant="caption"
            >
              <p>[ Somma di montante Lordo preventivi accettati ]</p>
              <p>[ Conteggio di montante Lordo preventivi accettati ]</p>
              <p>[ Somma di montante Lordo preventivi accettati agenzia]</p>
            </Typography> */}

          </div>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              height: 54,
              width: 177
            }}
          >
            <ChartLine data={[10, 15, 20, 10]} />
          </Box>
        </Grid>
      </Grid>
    </Card >
  );
}

export default ChartOverview;
